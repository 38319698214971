<template>
  <div class="rulerClass">
    <div style="width:1200px;margin: 60px 0;">
      <el-tabs :tab-position="tabPosition" v-model="selectModel" style="height: auto">
        <el-tab-pane label="服务协议" name="1">
          <service-agreement/>
        </el-tab-pane>
        <el-tab-pane label="隐私保护政策" name="2">
          <privacy-policy/>
        </el-tab-pane>
        <el-tab-pane label="销售合同" name="3">
          <cooperation-agreement/>
        </el-tab-pane>
        <el-tab-pane label="用户行为规范" name="4">
          <user-code-conduct/>
        </el-tab-pane>
        <el-tab-pane label="账户管理规范" name="5">
          <account-manager/>
        </el-tab-pane>
        <el-tab-pane label="知识产权声明" name="6">
          <intellectual-property/>
        </el-tab-pane>
        <el-tab-pane label="代理商手册" name="7">
          <Manual/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ServiceAgreement from "./ServiceAgreement";
import PrivacyPolicy from "./PrivacyPolicy";
import CooperationAgreement from "./CooperationAgreement";
import UserCodeConduct from "./UserCodeConduct";
import AccountManager from "./AccountManager";
import IntellectualProperty from "./IntellectualProperty";
import Manual from "./manual";

export default {
  name: "index",
  components: {
    ServiceAgreement,
    PrivacyPolicy,
    CooperationAgreement,
    UserCodeConduct,
    AccountManager,
    IntellectualProperty,
    Manual
  },
  data () {
    return {
      tabPosition: 'left',
      selectModel: 1
    }
  },
  watch: {
    $route: {
      handler () {
        // console.log(1111)
        if (this.$route.query.index) {
          this.selectModel = this.$route.query.index
        }
      }
    }
  },
  created () {
    let c = this.$route.query
    if (c) {
      this.selectModel = c.index
      // window.scrollTo(0,0)
    }
    this.$parent.gotoTop()
  }
}
</script>

<style scoped>
  .rulerClass {
    display: flex;;
    justify-content: center;
  }

  .rulerClass p {
    line-height: 30px;
  }
</style>
<style>
  .rulerClass .el-tabs__item.is-active {
    color: #185CFF;
  }

  .rulerClass .el-tabs__active-bar {
    background-color: #185CFF;
  }

  .rulerClass .el-tabs__item:hover {
    color: #185CFF;
  }
</style>
