<template>
  <div style="margin: 30px;line-height: 30px;font-family: '楷体'">

    <div><p style="font-size:16pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0"><span
        style="font-family:仿宋; font-size:16pt; font-weight:bold">企修通 销售合同</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:93.85pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">甲方（需方）：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">乙方（供方）：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:18.05pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0"><span
          style="font-family:仿宋; font-size:12pt">为明确甲、乙双方权利义务关系，根据《中华人民共和国民法典》及相关法律、法规，经双方友好协商一致，达成以下协议：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">第1条  </span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">产品</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">名称、规格、单价、数量，合计金额等信息详见下表：</span></p>
      <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:0pt; width:491.35pt">
        <tr style="height:25.5pt">
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:14.15pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">序号</span></p></td>
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:117pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">产品/软件</span><span
                style="font-family:仿宋; font-size:12pt">产品</span><span
                style="font-family:仿宋; font-size:12pt">服务</span><span style="font-family:仿宋; font-size:12pt">名称</span>
            </p></td>
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:95.5pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">规格/型号</span></p></td>
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:88.5pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">单价(元)</span></p></td>
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:92pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">数量(单位)</span></p></td>
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:74.45pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">合计金额(元)</span></p>
          </td>
        </tr>
        <tr style="height:14.3pt">
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:14.15pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">1</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:117pt">
            <p style="margin:0pt"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:95.5pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:88.5pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:92pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:74.45pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
        </tr>
        <tr style="height:14.3pt">
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:14.15pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">2</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:117pt">
            <p style="margin:0pt"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:95.5pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:88.5pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:92pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:74.45pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
        </tr>
        <tr style="height:14.3pt">
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:14.15pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">3</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:117pt">
            <p style="margin:0pt"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:95.5pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:88.5pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:92pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:74.45pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
        </tr>
        <tr style="height:24pt">
          <td colspan="5"
              style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:413.15pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">---------</span></p>
          </td>
          <td style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:74.45pt">
            <p style="margin:0pt; text-align:center"><span style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></td>
        </tr>
        <tr style="height:15.8pt">
          <td colspan="6"
              style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:489.1pt">
            <p style="margin:0pt"><span style="font-family:仿宋; font-size:12pt">大写：</span></p></td>
        </tr>
        <tr style="height:15.8pt">
          <td colspan="6"
              style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:489.1pt">
            <p style="margin:0pt"><span style="font-family:仿宋; font-size:12pt">总价中含               </span></p></td>
        </tr>
        <tr style="height:15.8pt">
          <td colspan="6"
              style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:0.38pt; vertical-align:middle; width:489.1pt">
            <p style="margin:0pt"><span style="font-family:仿宋; font-size:12pt">使用目的：</span></p></td>
        </tr>
        <tr style="height:0pt">
          <td style="width:15.65pt; border:none"></td>
          <td style="width:118.5pt; border:none"></td>
          <td style="width:97pt; border:none"></td>
          <td style="width:90pt; border:none"></td>
          <td style="width:93.5pt; border:none"></td>
          <td style="width:75.95pt; border:none"></td>
        </tr>
      </table>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">注：企修通产品详情见附件1《企修通系列产品说明》</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">第2条  货款支付方式</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">2.1 甲、乙双方一致确认本合同项下采用如下</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">       </span><span
          style="font-family:仿宋; font-size:12pt">（A/B/C/D）支付方式完成甲方应向乙方支付的货款：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">A.合同签订后</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline"> </span><span
          style="font-family:仿宋; font-size:12pt">日内付全款，款到发货；</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">B.月结，每月</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline"> </span><span
          style="font-family:仿宋; font-size:12pt">日前双方对上月甲方应付采购金额进行对账，</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline"> </span><span
          style="font-family:仿宋; font-size:12pt">日前甲方付清双方已完成对账的上月货款；</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">C.收货并确认验收后</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline"> </span><span
          style="font-family:仿宋; font-size:12pt">日付款； </span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">D.其他方式：</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">                                           </span><span
          style="font-family:仿宋; font-size:12pt">；</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">2.2 乙方承诺，应在收到甲方支付的货款后3个工作日内向甲方开具相应金额的合法有效的增值税专用发票。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">2.3 甲、乙双方确认，本合同项下甲方向乙方支付的任何款项，均应支付</span><span
          style="font-family:仿宋; font-size:12pt">至</span><span style="font-family:仿宋; font-size:12pt">如下</span><span
          style="font-family:仿宋; font-size:12pt">账号：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">开户行：</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">                                 </span><span
          style="font-family:仿宋; font-size:12pt">；</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">开户名：</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">                                 </span><span
          style="font-family:仿宋; font-size:12pt">；</span></p>
      <p style="margin:0pt; orphans:0; text-indent:21pt; widows:0"><span style="font-family:仿宋; font-size:12pt">账</span><span
          style="font-family:仿宋; font-size:12pt">  号：</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">                                 </span><span
          style="font-family:仿宋; font-size:12pt">。</span></p>
      <p style="margin:0pt; orphans:0; text-indent:21pt; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">第3条  交货时间、地点、方式、运费、风险转移、质量、包装等</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">3.1 </span><span style="font-family:仿宋; font-size:12pt">硬件产品</span><span
          style="font-family:仿宋; font-size:12pt">交货时间：乙方应在本合同签订之日起</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">      </span><span
          style="font-family:仿宋; font-size:12pt">日</span><span style="font-family:仿宋; font-size:12pt">内按照第1条的约定完成</span><span
          style="font-family:仿宋; font-size:12pt">硬件产品的</span><span style="font-family:仿宋; font-size:12pt">交货。并附上双方约定的、记录产品相关事项的资料。</span><span
          style="font-family:仿宋; font-size:12pt">软件产品的交付以双方的约定为准。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">3.2 交货方式：硬件产品</span><span style="font-family:仿宋; font-size:12pt">由乙方送至甲方指定</span><span
          style="font-family:仿宋; font-size:12pt">的</span><span style="font-family:仿宋; font-size:12pt">收货地点</span><span
          style="font-family:仿宋; font-size:12pt">；软件产品由乙方为甲方开通相应的使用账号</span><span
          style="font-family:仿宋; font-size:12pt">视为交付</span><span style="font-family:仿宋; font-size:12pt">。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">3.3 </span><span style="font-family:仿宋; font-size:12pt">硬件产品</span><span
          style="font-family:仿宋; font-size:12pt">交货地点：</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">                       </span><span
          style="font-family:仿宋; font-size:12pt">。</span><span style="font-family:仿宋; font-size:12pt">  </span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">3.4 运费：乙方应确保硬件产品安全、完好的情况下运输，运费由</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">乙</span><span
          style="font-family:仿宋; font-size:12pt">方承担。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt">    </span><span style="font-family:仿宋; font-size:12pt">3.5 风险转移：在乙方向甲方完成交付之前，</span><span
          style="font-family:仿宋; font-size:12pt">产品</span><span style="font-family:仿宋; font-size:12pt">的全部风险由乙方承担，交付之后由甲方自行承担。甲方在签收单上签字确认视为交付完成。</span><span
          style="font-family:仿宋; font-size:12pt">软件类产品，乙方提供账号或其他介质形式向甲方交付相关软件和账号信息的，</span><span
          style="font-family:仿宋; font-size:12pt">视为甲方已收到该软件，乙方已完成系统软件的交付。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">3.</span><span style="font-family:仿宋; font-size:12pt">6</span><span
          style="font-family:仿宋; font-size:12pt"> 包装：</span><span
          style="font-family:仿宋; font-size:12pt">硬件产品</span><span style="font-family:仿宋; font-size:12pt">的包装应符合国家或行业包装标准，如没有国家或行业包装标准，应当采用足以保护</span><span
          style="font-family:仿宋; font-size:12pt">硬件产品</span><span
          style="font-family:仿宋; font-size:12pt">安全、完好的包装方式。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">第</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">4</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">条 软件使用许可权及版权</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">   4.1 </span><span style="font-family:仿宋; font-size:12pt">本合同第1条所确定的软件的著作权归乙方所有，甲方如有非法解密、复制或者其他违反该协议的行为，乙方有权立即终止本协议，并追究甲方的法律责任。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:等线; font-size:10.5pt">   </span><span
          style="font-family:仿宋; font-size:12pt">4.2 本</span><span
          style="font-family:仿宋; font-size:12pt">合同第1条软件产品</span><span style="font-family:仿宋; font-size:12pt">服务是指乙方向甲方提供的企修通软件服务,企修通是乙方研发的设备全生命周期数字化管理平台，核心功能包含：数字化设备运维，数字化资产盘点，数字化固资回收等。乙方提供交付与维护</span><span
          style="font-family:仿宋; font-size:12pt">本服务</span><span style="font-family:仿宋; font-size:12pt">所需的完善服务，如产品培训，技术实施等。甲方仅可将乙方提供的</span><span
          style="font-family:仿宋; font-size:12pt">本服务</span><span style="font-family:仿宋; font-size:12pt">和与之相关的应用软件 (包括但不限于 SDK、软件主体、底层存储） 用于合法用途，甲方确保其使用</span><span
          style="font-family:仿宋; font-size:12pt">本服</span><span style="font-family:仿宋; font-size:12pt">务和与之相关的应用软件的行为符合可适用的法律法规的规定和监</span><span
          style="font-family:仿宋; font-size:12pt">管要求，不侵犯任何第三方的知识产权及其他合法权益。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:宋体; font-size:12pt">  </span><span style="font-family:仿宋; font-size:12pt"> 4.</span><span
          style="font-family:仿宋; font-size:12pt">3甲方只能为测试、存档、备份或经乙方同意的其他目的对系统和文档进行复制。甲方不得更改或删除系统中的任何所有权、版权、商标或商业秘密等乙方相关的标志。甲方不得破解、分解、逆向设计或逆向编译软件或其它保密信息。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">第</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">5</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">条 验收条款</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">5.1 </span><span
          style="font-family:仿宋; font-size:12pt">验收标准以国家、行业、</span><span
          style="font-family:仿宋; font-size:12pt">硬件产品</span><span
          style="font-family:仿宋; font-size:12pt">交付地有关质量标准为准。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0"><span
          style="font-family:仿宋; font-size:12pt">5</span><span style="font-family:仿宋; font-size:12pt">.</span><span
          style="font-family:仿宋; font-size:12pt">2</span><span
          style="font-family:仿宋; font-size:12pt"> 甲方应在签收之日起</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">  </span><span
          style="font-family:仿宋; font-size:12pt">日内完成对交付</span><span
          style="font-family:仿宋; font-size:12pt">硬件产品</span><span
          style="font-family:仿宋; font-size:12pt">的质量验收，如对交付</span><span style="font-family:仿宋; font-size:12pt">产品</span><span
          style="font-family:仿宋; font-size:12pt">的质量有任何异议，应在</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">  </span><span
          style="font-family:仿宋; font-size:12pt">日内以书面形式向乙方提出，乙方根据实际情况予以退换。</span><span
          style="font-family:仿宋; font-size:12pt">甲方使用乙方为其开通的软件服务账号，视为通过甲方验收。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0"><span
          style="font-family:仿宋; font-size:12pt">5</span><span style="font-family:仿宋; font-size:12pt">.</span><span
          style="font-family:仿宋; font-size:12pt">3</span><span style="font-family:仿宋; font-size:12pt"> 保修期限：</span><span
          style="font-family:仿宋; font-size:12pt">硬件产品</span><span style="font-family:仿宋; font-size:12pt">按国家三包法规定及生产厂家所规定的质保期进行保修。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">第</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">6</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">条  售后服务</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0"><span
          style="font-family:仿宋; font-size:12pt">6</span><span style="font-family:仿宋; font-size:12pt">.1 本项下</span><span
          style="font-family:仿宋; font-size:12pt">硬件产品</span><span style="font-family:仿宋; font-size:12pt">合同涉及的售后服务由乙方和生产厂家协调负责。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">6</span><span style="font-family:仿宋; font-size:12pt">.2 甲方因产品质量问题产生的退换货，运输费用由乙方承担。非因质量问题需要退换货的，由双方另行协商确定。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">6.3 软件售后服务：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">6.3.1</span><span style="font-family:仿宋; font-size:12pt"> 乙方为甲方提供一年期的免费服务（自软件产品开通账号之日起），包含软件版本的更新与升级，软件系统的远程技术指导。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">6.3.2</span><span
          style="font-family:仿宋; font-size:12pt"> 甲方通过电话</span><span
          style="font-family:仿宋; font-size:12pt">、</span><span style="font-family:仿宋; font-size:12pt">微信</span><span
          style="font-family:仿宋; font-size:12pt">和</span><span style="font-family:仿宋; font-size:12pt">E</span><span
          style="font-family:'Times New Roman'; font-size:12pt">-</span><span
          style="font-family:'Times New Roman'; font-size:12pt">mail</span><span style="font-family:仿宋; font-size:12pt">等方式向乙方提出技术服务需求，乙方有义务及时响应和认真服务，努力确保甲方所购软件的正常使用。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt"> 6.3.3</span><span style="font-family:仿宋; font-size:12pt"> 软件产品购买到期之日，该</span><span
          style="font-family:仿宋; font-size:12pt">软件</span><span
          style="font-family:仿宋; font-size:12pt">服务自动终止。</span><span style="font-family:仿宋; font-size:12pt">因甲方未及时续费，乙方关闭账号，因此造成的后果不承担责任。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">第</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">7</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">条   甲、乙双方的权利义务</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">7</span><span
          style="font-family:仿宋; font-size:12pt">.1 甲方的权利义务</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">7</span><span style="font-family:仿宋; font-size:12pt">.1.1 甲方有权要求乙方按时按质交付</span><span
          style="font-family:仿宋; font-size:12pt">硬件产品或提供软件产品服务。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">7</span><span style="font-family:仿宋; font-size:12pt">.1.2 甲方须按合同约定支付款项。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">7.1.3 甲方承诺不实施以下任何行为，否则，乙方有权立即停止向甲方提供的服务/软件，并对相关损害乙方权益的行为进行索赔：（1）向甲方以外的第三方</span><span
          style="font-family:仿宋; font-size:12pt">或公众</span><span style="font-family:仿宋; font-size:12pt">提供</span><span
          style="font-family:仿宋; font-size:12pt">（出租、出借、无偿使用、出售等任何方式）</span><span
          style="font-family:仿宋; font-size:12pt">乙方向其提供的服务/软件（2）将乙方提供的服务/软件用于双方约定以外的其他用途或目的</span><span
          style="font-family:仿宋; font-size:12pt">（包括学习和研究目的）</span><span style="font-family:仿宋; font-size:12pt">（3）复制或模仿乙方提供的服务/软件，对其进行改变、反编译等反向工程处理（4）复制或模仿乙方的任何设计、界面、功能和图表，并生成与乙方提供服务/软件相类似或竞争的产品及服务（5）使用乙方提供的服务/软件侵犯他人权益、有损他人声誉、不合法或危害他人等（6）干扰或破坏乙方提供的服务/软件。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">7</span><span
          style="font-family:仿宋; font-size:12pt">.2 乙方的权利义务</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">7</span><span style="font-family:仿宋; font-size:12pt">.2.1 乙方有权要求甲方按期支付款项；</span><span
          style="font-family:仿宋; font-size:12pt">甲方付清应付乙方的全部款项后，</span><span
          style="font-family:仿宋; font-size:12pt">方</span><span style="font-family:仿宋; font-size:12pt">享有软件产品的使用权。若经乙方催告，甲方仍未付款的，乙方可随时关闭甲方的账号，由此产生的不利后果由甲方自负。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify"><span
          style="font-family:仿宋; font-size:12pt">    7</span><span
          style="font-family:仿宋; font-size:12pt">.2.2</span><span style="font-family:仿宋; font-size:12pt"> 乙方保证其向甲方交付的硬件产品不存在质量瑕疵、缺陷</span><span
          style="font-family:仿宋; font-size:12pt">，</span><span style="font-family:仿宋; font-size:12pt">符合产品质量标准及相关法律法规的要求。乙方拥有软件的所有权，有权向甲方授予许可而不受任何第三方主张权利；</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt; font-weight:normal">7</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:normal">.2.3 乙方应保证</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:normal">硬件产品</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:normal">是全新、未使用过的原装合格正品，完全符合合同规定的质量、规格和性能的要求。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:0pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">第8条</span><span
          style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0; </span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold"> 免责条款</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">8.1</span><span
          style="font-family:仿宋; font-size:12pt">软件产品</span><span style="font-family:仿宋; font-size:12pt">是</span><span
          style="font-family:仿宋; font-size:12pt">作为不提供任何明确的或隐含的赔偿或担保的形式出售的。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">8.2</span><span style="font-family:仿宋; font-size:12pt">甲方同意自己承担使用软件产品的风险，在适用法律允许的最大范围内，乙方在任何情况下不就因使用或不能使用软件产品所发生的特殊的、意外的、非直接或间接的损失承担赔偿责任。即使甲方已事先被乙方告知该损害发生的可能性。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">8.3</span><span
          style="font-family:仿宋; font-size:12pt">甲方必须了解使用软件</span><span style="font-family:仿宋; font-size:12pt">产品</span><span
          style="font-family:仿宋; font-size:12pt">的风险。必要时，乙方所承担的责任仅限于产品版本更换，或在第一次成功安装本软件前退还购买费用。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:0pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">第9条</span><span
          style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0; </span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold"> 违约责任</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">9.1 甲方逾期付款的，每逾期1日，甲方向乙方偿付逾期付款部分货款总金额的5‰的滞纳金。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">9.2 乙方逾期交付</span><span
          style="font-family:仿宋; font-size:12pt">产品</span><span style="font-family:仿宋; font-size:12pt">的，每逾期1日，乙方应向甲方偿付逾期交货部分金额的5‰的滞纳金。如乙方逾期交货达10日，甲方有权单方解除合同，并要求乙方返还甲方已支付的所有货款，解除合同的通知</span><span
          style="font-family:仿宋; font-size:12pt">自达到</span><span style="font-family:仿宋; font-size:12pt">乙方时生效。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">9.3 乙方所交付的</span><span
          style="font-family:仿宋; font-size:12pt">硬件产品</span><span style="font-family:仿宋; font-size:12pt">品种、型号、规格等不符合合同规定的，甲方有权拒收，乙方赔偿由此给甲方造成的损失。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">第10条  通知条款</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">10.1 各方通讯方式如下：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">10.1.1 甲方：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">联系人：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">地  址：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">电  话：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">邮</span><span style="font-family:仿宋; font-size:12pt">  箱：</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">10.1.2 乙方</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">联系人：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">地  址：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">电  话：</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">邮</span><span style="font-family:仿宋; font-size:12pt">  箱：</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">10.2 </span><span style="font-family:仿宋; font-size:12pt">协议双方一致确认上述通讯地址和联系方式为履行协议、解决协议争议时一方接收对方商业文件信函或司法机关（法院、仲裁机构）诉讼、仲裁文书的地址和联系方式。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">10.3 一方变更通知或通讯地址，应自变更之日起5日内，以书面形式通知对方。否则，由未通知方承担由此而引起的相关责任。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">第</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">1</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">1条  争议的处理</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt; font-weight:normal">1</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:normal">1.1 因</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:normal">硬件产品</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:normal">的质量问题产生争议的，</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:normal">可以</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:normal">邀请国家认可的质量检测机构对产品质量进行鉴定。产品符合标准的，鉴定费由甲方承担，产品不符合质量</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:normal">标准的，鉴定费由乙方承担。</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold"> </span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">1</span><span style="font-family:仿宋; font-size:12pt">1.2 因履行本协议引起的与本协议有关的争议，双方应首先通过友好协商解决。如协商不能解决争议，</span><span
          style="font-family:仿宋; font-size:12pt">应提交</span><span style="font-family:仿宋; font-size:12pt">至重庆仲裁委员会，由其按照申请仲裁时该委员会有效的仲裁规则在重庆进行仲裁。仲裁裁决是终局性的，对双方均有约束力。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">实现债权的费用（包括但不限于诉讼费、仲裁费、保全费、保全担保费、律师费、执行费、公告费等）由违约方承担。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">第</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">1</span><span
          style="font-family:仿宋; font-size:12pt; font-weight:bold">2条  其他</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">1</span><span style="font-family:仿宋; font-size:12pt">2.1 本协议未尽事宜，由甲、乙双方另行协商确定。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">1</span><span style="font-family:仿宋; font-size:12pt">2</span><span
          style="font-family:仿宋; font-size:12pt">.2 除本协议的全部约定以外，甲方</span><span style="font-family:仿宋; font-size:12pt">或甲方用户</span><span
          style="font-family:仿宋; font-size:12pt">在</span><span style="font-family:仿宋; font-size:12pt">使用软件前，还必须接受软件上公示的规则、通知、提示的</span><span
          style="font-family:仿宋; font-size:12pt">约束，</span><span style="font-family:仿宋; font-size:12pt">甲方</span><span
          style="font-family:仿宋; font-size:12pt">或甲方用户</span><span style="font-family:仿宋; font-size:12pt">一旦开始安装使用软件，即被视为完全理解并接受本协议</span><span
          style="font-family:仿宋; font-size:12pt">及软件上公示的规则、通知、注意事项。</span><span style="font-family:仿宋; font-size:12pt">乙方有权根据其内部管理流程的变更、技术调整等原因，单方对</span><span
          style="font-family:仿宋; font-size:12pt">软件</span><span style="font-family:仿宋; font-size:12pt">规则</span><span
          style="font-family:仿宋; font-size:12pt">、通知、提示的内容</span><span
          style="font-family:仿宋; font-size:12pt">进行更新并公示。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">1</span><span style="font-family:仿宋; font-size:12pt">2.</span><span
          style="font-family:仿宋; font-size:12pt">3</span><span
          style="font-family:仿宋; font-size:12pt"> 本协议自双方盖章</span><span
          style="font-family:仿宋; font-size:12pt">（电子签章有效）</span><span
          style="font-family:仿宋; font-size:12pt">之日起生效。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">1</span><span style="font-family:仿宋; font-size:12pt">2.</span><span
          style="font-family:仿宋; font-size:12pt">4</span><span style="font-family:仿宋; font-size:12pt"> </span><span
          style="font-family:仿宋; font-size:12pt">本合同一式</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">  </span><span
          style="font-family:仿宋; font-size:12pt">份，甲方执</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">  </span><span
          style="font-family:仿宋; font-size:12pt">份，乙方执</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline">  </span><span
          style="font-family:仿宋; font-size:12pt">份</span><span style="font-family:仿宋; font-size:12pt">，各</span><span
          style="font-family:仿宋; font-size:12pt">份具有</span><span style="font-family:仿宋; font-size:12pt">同等法律效力。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">    </span><span style="font-family:仿宋; font-size:12pt">1</span><span
          style="font-family:仿宋; font-size:12pt">2.</span><span style="font-family:仿宋; font-size:12pt">5</span><span
          style="font-family:仿宋; font-size:12pt"> 补充约定：</span><span
          style="font-family:仿宋; font-size:12pt; text-decoration:underline; padding-right: 200px;border-bottom: 1px solid #c4c4c4">                                                </span><span
          style="font-family:仿宋; font-size:12pt">。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:136.5pt; widows:0">
        <span style="font-family:仿宋; font-size:12pt">（以下无正文）</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt;margin-right: 200px">甲方（盖章）：</span><span
          style="font-family:仿宋; font-size:12pt"> 乙方（盖章）</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt">签订日期：   年   月   日     </span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
          style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt"><span style="font-family:仿宋; font-size:12pt"> </span><span
          style="font-family:仿宋; font-size:12pt">附件1 企修通系列产品介绍</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">一、软件产品及功能</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">1.服务</span><span style="font-family:仿宋; font-size:12pt">商管理</span><span
          style="font-family:仿宋; font-size:12pt">后台：</span><span style="font-family:仿宋; font-size:12pt">作为管理终端，对维修、租赁业务进行调度和管理。可对维修/</span><span
          style="font-family:仿宋; font-size:12pt">维保订单</span><span style="font-family:仿宋; font-size:12pt">进行指派与监管、租赁业务智能化管理及抄表、自有设备管理、客户信息及设备管理、维修员管理、配件耗材库管理及业务相关报表统计。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">2.工程师软件：工程师登录企修通工程师端（</span><span
          style="font-family:仿宋; font-size:12pt">微信小</span><span
          style="font-family:仿宋; font-size:12pt">程序）的账号，用于工程师接单。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">3.大屏数据软件：为服务商提供的可视化数字界面，记录</span><span
          style="font-family:仿宋; font-size:12pt">设备维保记录</span><span style="font-family:仿宋; font-size:12pt">。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">4.</span><span style="font-family:仿宋; font-size:12pt">云盒软件</span><span
          style="font-family:仿宋; font-size:12pt">：指的</span><span
          style="font-family:仿宋; font-size:12pt">是云盒授权</span><span style="font-family:仿宋; font-size:12pt">码，</span><span
          style="font-family:仿宋; font-size:12pt">用于云盒绑</span><span style="font-family:仿宋; font-size:12pt">定设备。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">5.盘点软件：用于客户固定物资设备盘点。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">二、硬件产品及功能</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">1.条码打印机：用于打印带有独立二</span><span style="font-family:仿宋; font-size:12pt">维码可</span><span
          style="font-family:仿宋; font-size:12pt">粘贴的设备标签(标签无RFID芯片)。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">2.资产盘点机：</span><span
          style="font-family:仿宋; font-size:12pt">用于扫码带有</span><span style="font-family:仿宋; font-size:12pt">RFID芯片，可高效地对设备数量进行盘点并统计。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">3.标签（条码资产）：可粘贴的空白标签</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">4.碳带：用于标签打印机配合使用。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">5.RFID 打印机：打印带有独立二</span><span style="font-family:仿宋; font-size:12pt">维码可</span><span
          style="font-family:仿宋; font-size:12pt">粘贴的设备标签(标签含RFID芯片)。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">6.RFID 盘点机：</span><span
          style="font-family:仿宋; font-size:12pt">手持扫码终端设备</span><span style="font-family:仿宋; font-size:12pt">，用于扫描统计含有RFID芯片的设备。</span>
      </p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">7.非金属电子标签：张贴在金属表面无法扫描信号盘点；盘点信号接收距离3米。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">8.抗金属电子标签：张贴在金属表面可扫描信号盘点；盘点信号接收距离3米。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; text-indent:21pt"><span
          style="font-family:仿宋; font-size:12pt">9.云盒：主要用于复印机和打印机设备管理，可进行远程抄表、设备故障预警以及设备低耗材余量监管。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0"><span
          style="font-family:仿宋; font-size:12pt">&#xa0;</span></p></div>
    <div style="line-height: 30px;cursor: pointer;color: blue"><a href="/word/企修通销售合同.docx">企修通销售附件下载</a></div>

  </div>
</template>

<script>
export default {
  name: "CooperationAgreement"
}
</script>

<style scoped>

</style>
