<template>
    <div style="margin: 30px;line-height: 30px">
        <p>
        <p class="MsoNormal" align="center" style="margin-left:0cm;text-align:center;">
            <b>隐私保护政策<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;text-indent:0cm;">
            <b>1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>提示条款<span></span></b>
        </p>
        <p class="MsoNormal" style="margin-left:21.1pt;text-indent:21.3pt;">
            您的信任对我们非常重要，我们深知个人信息对您的重要性 （<b><u>本政策所指个人信息及个人敏感信息所包含的内容出自《信息安全技术
            个人信息安全规范》<span>GB/T35273-2017</span></u></b>）， 我们将按照法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。鉴于此，企修通平台制定本《隐私保护政策》（下称<span>“</span>本政策<span>/</span>本隐私政策<span>”</span>）并提醒您：<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.1pt;text-indent:21.3pt;">
            本政策适用于企修通平台提供的所有产品和服务。<b><u>如我们关联公司的产品或服务中使用了企修通平台的产品或服务（例如使用企修通平台帐号登陆）但未设独立隐私权政策的，则本政策同样适用于该产品或服务。</u></b><span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.1pt;text-indent:21.3pt;">
            需要特别说明的是，<b><u>本政策不适用其他独立第三方向您提供的服务，也不适用于平台中已另行独立设置法律声明及隐私权政策的产品或服务。</u></b>例如平台上第三方依托本平台向您提供服务时，您向第三方提供的个人信息不适用于本隐私政策，企修通平台经营者对任何第三方使用由您提供的信息不承担任何责任。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.1pt;text-indent:21.3pt;">
            <b><u>在使用平台各项产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体<span>/</span>粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用平台各项产品或服务，即表示您已充分理解并同意本政策。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;text-indent:.05pt;">
            <b>2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>定义<u><span></span></u></b>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>企修通平台：</b>指企修通官网，包含企修通客户端、企修通服务商端、企修通渠道商端；企修通用户端（微信小程序）、企修通工程师端（微信小程序）。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>企修通平台服务：</b>指企修通平台基于互联网，以包含企修通平台官网、用户端、工程师端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>企修通平台经营者：</b>指重庆企修通网络科技有限公司及其关联方。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>企修通平台规则：</b>指企修通平台经营者在企修通平台内已经公示及<span>/</span>或未来将公示的各类政策、协议、解读、规则等。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>用户：</b>下称“您”或“用户”，是指注册、登录、使用、浏览、获取本协议项下服务的个人或组织。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>个人信息：</b>指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括自然人姓名、出生日期、身份证号码、个人生物识别信息、住址、电话号码等。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>个人敏感信息：</b>指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇的个人信息，包括身份证号码、个人生物识别信息、银行账户、财产信息、行踪轨迹、通信内容、未成年人信息、健康生理信息等息。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>个人信息删除：</b>指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>如何收集和使用信息<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>在您使用我们的产品及<span>/</span>或服务时，我们需要<span>/</span>可能需要收集和使用您的个人信息包括如下两种：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.1.1&nbsp;&nbsp;&nbsp; </b>为实现向您提供我们产品及<span>/</span>或服务的基本功能，您须授权我们收集、使用的必要信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及<span>/</span>或服务。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.1.2&nbsp;&nbsp;&nbsp; </b>为实现向您提供我们的产品及<span>/</span>或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及<span>/</span>或服务的基本功能。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>基础功能信息收集和使用<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.2.1&nbsp;&nbsp;&nbsp; </b>企业注册企修通账号时，需严格遵守企修通平台的注册流程（详情请见《企修通平台入驻规范》），企修通平台经营者依照注册流程设置完毕后，企业则可以获取到企业账号。提供相关注册信息并同意《企修通平台服务协议》和本政策，你可以使用企修通平台进行维修、保养、报修、租赁等服务。如果您选择不提供为实现平台基础业务功能的必备信息或使用其中某类功能的必备信息，将导致我们无法为您提供服务。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.2.2&nbsp;&nbsp;&nbsp; </b>您注册成为平台用户后，可在对应平台内进行相应的操作。在此过程中，我们会收集您的客户信息、设备信息、订单信息、员工信息、业绩信息等。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>3.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>附加功能信息收集和使用<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.3.1&nbsp;&nbsp;&nbsp; </b>基于相机<span>/</span>摄像头的附加功能。您可在开启相机<span>/</span>摄像头权限后使用该功能进行扫码用于登陆、支付、拍摄照片或视频上传、分享，以及特定场景下经您授权的人脸识别等功能。当您使用该附加功能进行人脸识别时我们会收集您的面部特征，且严格在经您授权同意的范围内使用。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.3.2&nbsp;&nbsp;&nbsp; </b>基于麦克风的语音技术的附加功能。您可在开启麦克风权限后使用麦克风实现语音连麦聊天或录音。在此过程中，我们会收集您的录音内容。请您知晓，即使您已同意开启麦克风权限，我们也仅会在您主动点击客户端内麦克风图标时通过麦克风获取语音信息。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.3.3&nbsp;&nbsp;&nbsp; </b>定位功能。当您开启设备定位功能并使用企修通基于位置提供的相关服务时，我们会通过<span>IP</span>地址、<span>GPS</span>、<span>WLAN</span>或基站途径获取您的地理位置信息。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.3.4&nbsp;&nbsp;&nbsp; </b>客户服务。当您向平台提起投诉、申诉或进行咨询时，为了方便与您联系或帮助您解决问题，我们可能需要您提供姓名、手机号码、电子邮件。如您拒绝提供上述信息，我们可能无法及时反馈投诉、申诉或咨询结果。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.3.5&nbsp;&nbsp;&nbsp; </b>安全保障。为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免受侵害，更好的预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络入侵等安全风险，我们可能使用或整合您的帐号信息、交易信息、设备信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您帐号交易风险、进行身份验证、检测及防范安全事件，并采取必要的记录、审计、分析、处置措施。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.3.6&nbsp;&nbsp;&nbsp; </b>我们会收集您的信息以便优化企修通产品，提供给您更优质的服务。我们需要收集您的设备信息、员工信息、位置作特征分析或用户画像等。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>3.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>敏感信息<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.4.1&nbsp;&nbsp;&nbsp; </b>在向平台提供任何属于敏感信息的个人信息前，请您清楚考虑该等提供是恰当的并且同意您的个人敏感信息可按本政策所述的目的和方式进行处理。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.4.2&nbsp;&nbsp;&nbsp; </b>我们会在得到您的同意后收集和使用您的敏感信息以实现与平台业务相关的功能，并允许您对这些敏感信息的收集与使用做出不同意的选择，但是拒绝使用这些信息会影响您使用相关功能。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>3.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>征得授权同意的例外<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:0cm;">
            在以下情形中，我们可以在不征得您授权同意的情况下收集、使用一些必要的个人信息：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.5.1&nbsp;&nbsp;&nbsp; </b>与国家安全、国防安全直接相关的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.5.2&nbsp;&nbsp;&nbsp; </b>与公共安全、公共卫生、重大公共利益直接相关的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.5.3&nbsp;&nbsp;&nbsp; </b>与犯罪侦查、起诉、审判和判决执行等直接相关的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.5.4&nbsp;&nbsp;&nbsp; </b>出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.5.5&nbsp;&nbsp;&nbsp; </b>所收集的个人信息是您自行向社会公众公开的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.5.6&nbsp;&nbsp;&nbsp; </b>从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.5.7&nbsp;&nbsp;&nbsp; </b>根据您的要求签订和履行合同所必需的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.5.8&nbsp;&nbsp;&nbsp; </b>用于维护平台的产品和<span>/</span>或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.5.9&nbsp;&nbsp;&nbsp; </b>为合法的新闻报道所必需的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.5.10</b>学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>3.5.11</b>法律法规规定的其他情形。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:21.2pt;">
            如我们停止运营平台服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>如何共享、转让、公开披露信息<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>共享<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:0cm;">
            平台对您的信息承担保密义务，不会出售或出租您的任何信息（包括不外泄客户、服务商的设备、订单、业务等数据），但以下情况除外：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.1.1&nbsp;&nbsp;&nbsp; </b>在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.1.2&nbsp;&nbsp;&nbsp; </b>在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.1.3&nbsp;&nbsp;&nbsp; </b>在法律要求或允许的范围内，为了保护平台及其用户或社会公众的利益、财产或安全免遭损害而有必要提供您的个人信息给第三方。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.1.4&nbsp;&nbsp;&nbsp; </b>与关联公司间共享：为便于我们基于平台帐号向您提供产品或服务，推荐您可能感兴趣的信息，识别帐号异常，您的个人信息可能会与我们的关联公司和<span>/</span>或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.1.5&nbsp;&nbsp;&nbsp; </b>与授权合作伙伴共享：为了向您提供更完善、优质的产品和服务，我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本隐私权政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。同时，我们会与合作伙伴签署严格的保密协议。如果您拒绝我们的合作伙伴在提供服务时收集为提供服务所必须的个人信息，将可能导致您无法在平台中使用该第三方服务。目前，我们的授权合作伙伴包括一下类型：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:99.2pt;text-indent:-35.4pt;">
            <b>4.1.5.1&nbsp;&nbsp; </b>广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人信息提供给广告、分析服务的合作伙伴分享。对于广告合作伙伴，我们可能会向其提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行匿名化处理，以便它不会识别到您个人。对于分析数据的伙伴，为了更好的分析用户的使用情况，我们可能向其提供用户的数量、地区分布、活跃情况等数据，但我们仅会向这些合作伙伴提供不能识别个人身份的统计信息。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:99.2pt;text-indent:-35.4pt;">
            <b>4.1.5.2&nbsp;&nbsp; </b>渠道商、服务商和其他合作伙伴。我们将信息发送给在全球范围内支持我们业务的渠道商、服务商和其他合作伙伴共享，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式，衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:99.2pt;text-indent:21.2pt;">
            我们会对授权合作伙伴获取有关信息的应用程序接口（<span>API</span>）、软件工具开发包（<span>SDK</span>）进行严格的安全测试，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策及其他任何相关的保密和安全措施来处理个人信息。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>转让<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:0cm;">
            平台不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.2.1&nbsp;&nbsp;&nbsp; </b>获得您的明确同意后，我们会向其他方转让您的个人信息；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.2.2&nbsp;&nbsp;&nbsp; </b>在平台服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续收本政策的约束，否则我们将要求该公司、组织或个人重新向您征求授权同意。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>公开披露<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:0cm;">
            平台仅会在以下情况下，公开披露您的个人信息：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.3.1&nbsp;&nbsp;&nbsp; </b>获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.3.2&nbsp;&nbsp;&nbsp; </b>如果我们确定您出现违反法律法规或严重违反平台相关协议及规则的情况，或为保护平台用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或征得您同意的情况下披露关于您的个人信息。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>4.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>共享、转让、公开披露个人信息时事先征得授权同意的例外<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:0cm;">
            以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.4.1&nbsp;&nbsp;&nbsp; </b>与国家安全、国防安全有关的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.4.2&nbsp;&nbsp;&nbsp; </b>与公共安全、公共卫生、重大公共利益有关的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.4.3&nbsp;&nbsp;&nbsp; </b>与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.4.4&nbsp;&nbsp;&nbsp; </b>出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.4.5&nbsp;&nbsp;&nbsp; </b>您自行向社会公众公开的个人信息；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.4.6&nbsp;&nbsp;&nbsp; </b>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.4.7&nbsp;&nbsp;&nbsp; </b>学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.4.8&nbsp;&nbsp;&nbsp; </b>法律法规规定的其他情形。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:0cm;">
            请知悉，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征<span> </span>得您的同意。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b><span>Cookie</span>的使用<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            为使您获得更轻松的访问体验，您访问平台或使用平台提供的服务时，平台可能会通过小型数据文件识别您的身份，这么做是帮您省去重复输入注册信息的步骤，或者帮助判断您的帐号安全。这些数据文件可能是<span>Cookie</span>，<span>Flash Cookie</span>，或您的浏览器或关联应用程序提供的其他本地存储（统称<span>“Cookie”</span>）。 请您理解，平台的某些服务只能通过使用<span>“Cookie”</span>才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对<span>Cookie</span>的接受程度或者拒绝平台的<span>Cookie</span>，但这一举动在某些情况下可能会影响您安全访问平台相关网站和使用平台提供的服务。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>信息存储<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            我们在中华人民共和国境内运营中收集和产生的个人信息存储在中国境内，不会对您的个人信息跨境传输。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            我们承诺只会在达成本政策所述目的所必需的最短时间保留您的个人信息。但在下列情况下，我们可能因需符合法律要求，更改个人信息的存储时间：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.1.1&nbsp;&nbsp;&nbsp; </b>为遵守适用的法律法规规定，例如，《中华人民共和国网络安全法》第二十一条第三款要求监测、记录网络运行状态、网络安全事件的技术措施的网络日志留存不得少于六个月；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.1.2&nbsp;&nbsp;&nbsp; </b>为遵守法院判决、裁定或其他法律程序的规定；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.1.3&nbsp;&nbsp;&nbsp; </b>为遵守政府机关或法定授权组织的要求；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.1.4&nbsp;&nbsp;&nbsp; </b>为执行平台协议或本政策、维护社会公共利益，保护平台经营者、其他用户或雇员的人身安全或其他合法权益所合理必需的用途。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:42.55pt;text-indent:0cm;">
            在您的个人信息超出保留期间后，我们会删除您的个人信息，或使其匿名化处理。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>如何保护您的信息<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            为保障您的信息安全，平台已采取符合业界标准、合理可行的各类物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于设置部署主机入侵检测系统、重要敏感数据加密存储、日志记录安全审计。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            平台对可能接触到您的信息的员工采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。平台会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，平台将尽力做到使您的信息不被泄漏、毁损或丢失。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            您的帐号均有安全保护功能，请妥善保管您的帐号及密码信息。平台将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。互联网并非绝对安全的环境，尽管有前述安全措施，但同时也请您理解在信息网络上不存在“完善的安全措施”。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            在使用平台服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如银行账户信息、联络方式或者邮政地址。我们强烈建议您不要使用非平台推荐的通信方式发送您的信息。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是你的帐号及密码发生泄露，请您立即联络客服，以便平台经营者采取相应措施。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>您如何管理您的信息<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>信息管理<span></span></b>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            平台非常重视您对个人信息的关注，并尽全力保护您对于自己个人信息访问、更正及删除的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括（平台内现有或未来将有该类相关服务）：<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            您可通过企业后台访问、修改您的手机号码信息；访问、修改您设置的帐号密码；访问、修改您的邮箱信息；访问您的实名认证<span>/</span>身份证认证信息。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            当您发现我们处理关于您的信息有错误时，您有权要求我们做出更正或补充。我们在提供服务的过程中，可能需要您开通一些设备权限，例如通知、相册、相机、麦克风、手机通讯录、蓝牙等访问权限。您可以在设备的【设置】功能中随时选择关闭部分或者全部权限，从而拒绝平台收集您的个人信息。在不同的设备中，权限显示方式及关闭方式可能有所不同，具体参考设备及系统开发方说明或指引。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>8.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>用户授权撤回<span></span></b>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            您可以通过联系平台经营者的途径撤销已同意的授权：<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            您申请授权撤回后，平台将不再处理相应的个人信息。请您理解，因您的授权撤回，我们无法继续为您提供撤回同意或授权所对应的特定功能或<span>/</span>和服务。但您撤回同意的决定，不会影响此前基于您的授权而展开的个人信息处理。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>8.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>帐号注销</b><span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>8.3.1&nbsp;&nbsp;&nbsp; </b>企业账号的注销<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:42.55pt;">
            由于企修通平台的特殊性，企业账户的注销必须严格遵守《企修通平台退出规则》并书面形式向企修通平台经营者提前<span>15</span>个工作日进行通知。用户提出注销申请后并满足《企修通平台退出规则》条件后，企修通平台经营者会根据《企修通平台退出规则》相关流程注销企业账户。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:42.55pt;">
            企业账户注销后，该企业账户下的所有子账户将无法继续使用。企修通平台经营者将遵照平台规则进行相关数据的删除或隐匿处理，各类账户下存在余额的，余额将返还到绑定的相应银行卡中或等待用户在<span>30</span>个工作日内处理完毕后再行处理。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>8.3.2&nbsp;&nbsp;&nbsp; </b>子账号的注销<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:42.55pt;">
            由于企修通平台用户账户设立的特殊性，子账号仅能通过其所在企业的主账号进行账户注销的操作。但企修通平台仍保留相关的渠道（包括但不限于线上程序、客服电话等）协助您进行账户的注销，您也可以通过企修通平台官方网站（<span>www.matefix.cn</span>）联系平台经营者进行处理。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>如何处理未成年人信息<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            平台的产品、网站和服务主要面向成人。若您是<span>18</span>周岁以下的未成年人，在使用我们的产品和<span>/</span>或服务前，您应在监护人的陪同下阅读本政策，并应确保已征得您的监护人同意后使用我们的服务并向我们提供您的信息。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            若您是未成年人的监护人，当您对您所监护的未成年人使用我们的服务或其向我们提供的用户信息有任何疑问时，请您及时与我们联系。我们将根据国家相关法律法规及本政策的规定保护未成年人用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会尽快删除相关数据。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>本隐私政策如何更新<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:0cm;">
            我们的隐私权政策可能变更。<b><span></span></b>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            未经您明确同意，平台不会限制您按照本政策所应享有的权利。我们会通过电子邮件信、信息推送通知等合理方式告知您，以便您能及时了解本政策所做的任何变更。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            对于重大变更，视具体情况平台可能还会提供更为显著的通知说明本政策的具体变更内容。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            本政策所指的重大变更包括但不限于：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>平台的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>平台在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>10.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>个人信息共享、转让或公开披露的主要对象发生变化；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>10.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>您参与个人信息处理方面的权利及其行使方式发生重大变化；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>10.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>平台负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>10.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>个人信息安全影响评估报告表明存在高风险<span>.</span>
        </p>
        <p class="MsoNormal" style="margin-left:21.25pt;text-indent:21.2pt;">
            您不同意修改后的隐私权政策，您有权并应立即停止使用平台服务。如果您继续使用平台服务，则视为您接受平台经营者对本政策相关条款所做的修改。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>法律适用、管辖及其他<span></span></b>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及<span>/</span>或行业惯例。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            您因使用平台服务所产生及与平台服务有关的争议，由平台经营者与您协商解决。协商不成时，<a name="_Hlk14856950"></a>您同意将纠纷或争议提交至平台经营者所在地有管辖权的司法机构管辖。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            <span></span>
        </p>
        <p class="MsoNormal">
            <b><span>12&nbsp;&nbsp; </span>本规定自颁布之日起执行。<span></span></b>
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <b><span>13&nbsp;&nbsp; </span>本规定修订权及最终解释权归企修通所有。</b><span></span>
        </p>
        <p class="MsoListParagraph" align="right" style="margin-left:21.3pt;text-align:right;text-indent:0cm;">
            重庆企修通网络科技有限公司<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:0cm;text-indent:21.0pt;">
            <span></span>
        </p>
        </p>
        <p>
            <br />
        </p>
        <div style="line-height: 30px;cursor: pointer;color: blue"><a href="/word/企修通平台隐私保护政策.docx">企修通平台隐私保护政策附件下载</a></div>
    </div>
</template>

<script>
    export default {
        name: "PrivacyPolicy"
    }
</script>

<style scoped>

</style>
