<template>
  <div style="margin: 30px;line-height: 30px;font-family: '仿宋'">
    <div><p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
        style="font-family:仿宋; font-size:12pt; font-weight:bold">企修通代理商手册</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">一、企修通代理商准入规则</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:justify; widows:0"><a
          name="_Hlk106813280"><span style="font-family:仿宋; font-size:12pt">1. </span><span
          style="font-family:仿宋; font-size:12pt">乙</span><span
          style="font-family:仿宋; font-size:12pt">方申请成为平台</span><span
          style="font-family:仿宋; font-size:12pt">代理商</span><span
          style="font-family:仿宋; font-size:12pt">，开展经营活动，须满足以下条件：</span></a></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">(1)</span><span style="font-family:仿宋; font-size:12pt">乙</span><span
          style="font-family:仿宋; font-size:12pt">方已依照中华人民共和国法律注册并领取合法有效的营业执照及其他经营许可</span><span
          style="font-family:仿宋; font-size:12pt">；</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt"> (2)</span><span style="font-family:仿宋; font-size:12pt">乙</span><span
          style="font-family:仿宋; font-size:12pt">方已达到</span><span
          style="font-family:仿宋; font-size:12pt">企修通合作</span><span
          style="font-family:仿宋; font-size:12pt">标准</span><span
          style="font-family:仿宋; font-size:12pt">并通过甲方资质审核</span><span
          style="font-family:仿宋; font-size:12pt">。</span><span style="font-family:仿宋; font-size:12pt"> </span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">2. </span><span
          style="font-family:仿宋; font-size:12pt">企修通代理商资质</span><span
          style="font-family:仿宋; font-size:12pt">证明文件</span><span style="font-family:仿宋; font-size:12pt">：</span><a
          name="_Hlk106813374"><span style="font-family:仿宋; font-size:12pt"> </span></a></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0"><span
          style="font-family:仿宋; font-size:12pt">代理</span><span
          style="font-family:仿宋; font-size:12pt">商资质审核清单</span><span
          style="-aw-bookmark-end:_Hlk106813374"></span></p>
      <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:5.65pt; width:407.85pt">
        <tr style="height:12.85pt">
          <td colspan="2"
              style="background-color:#ffc000; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:396.3pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:center"><a name="_Hlk106813384"><span
                style="font-family:仿宋; font-size:12pt; font-weight:bold">服务商资质审核清单</span></a></p></td>
        </tr>
        <tr style="height:13.65pt">
          <td style="background-color:#ffc000; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:96.7pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:center"><span
                style="font-family:仿宋; font-size:12pt; font-weight:bold">名称</span></p></td>
          <td style="background-color:#ffc000; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.4pt; padding-right:5.03pt; vertical-align:middle; width:288.8pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:center"><span
                style="font-family:仿宋; font-size:12pt; font-weight:bold">要求</span></p></td>
        </tr>
        <tr style="height:13.65pt">
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:96.7pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:center"><span
                style="font-family:仿宋; font-size:12pt">企业营业执照</span></p></td>
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.4pt; padding-right:5.03pt; vertical-align:middle; width:288.8pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt"><span style="font-family:仿宋; font-size:12pt">复印件加盖公司鲜章（不具有法人资格的，需提供总公司的授权书）</span>
            </p></td>
        </tr>
        <tr style="height:13.65pt">
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:96.7pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:center"><span
                style="font-family:仿宋; font-size:12pt">法人代表身份证</span></p></td>
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.4pt; padding-right:5.03pt; vertical-align:middle; width:288.8pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt"><span style="font-family:仿宋; font-size:12pt">复印件加盖公司鲜章（正反面同一A4纸上）</span>
            </p></td>
        </tr>
        <tr style="height:13.65pt">
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:96.7pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:center"><span
                style="font-family:仿宋; font-size:12pt">税务登记证</span></p></td>
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.4pt; padding-right:5.03pt; vertical-align:middle; width:288.8pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt"><span style="font-family:仿宋; font-size:12pt">税务登记证加盖公司鲜章（需在有效期内）</span>
            </p></td>
        </tr>
        <tr style="height:13.65pt">
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:96.7pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:center"><span
                style="font-family:仿宋; font-size:12pt">组织机构代码证</span></p></td>
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.4pt; padding-right:5.03pt; vertical-align:middle; width:288.8pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt"><span style="font-family:仿宋; font-size:12pt">组织机构代码证加盖公司鲜章（需在有效期内）</span>
            </p></td>
        </tr>
        <tr style="height:13.65pt">
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:96.7pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:center"><span
                style="font-family:仿宋; font-size:12pt">开户银行许可证</span></p></td>
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.4pt; padding-right:5.03pt; vertical-align:middle; width:288.8pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt"><span style="font-family:仿宋; font-size:12pt">开户银行许可证加盖公司鲜章（需在有效期内）</span>
            </p></td>
        </tr>
        <tr style="height:13.65pt">
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:96.7pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt; text-align:center"><span
                style="font-family:仿宋; font-size:12pt">行业特种资质</span></p></td>
          <td style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.4pt; padding-right:5.03pt; vertical-align:middle; width:288.8pt">
            <p style="font-size:12pt; line-height:150%; margin:0pt"><span style="font-family:仿宋; font-size:12pt">复印件加盖公司鲜章（涉及部分特殊服务需取得的从业特种资质）</span>
            </p></td>
        </tr>
        <tr style="height:0pt">
          <td style="width:107.5pt; border:none"></td>
          <td style="width:299.6pt; border:none"></td>
        </tr>
      </table>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">注：以上基本资质要求的证件只需提供复印件（加盖公章）或者原件扫描件、图片；</span><span
          style="-aw-bookmark-end:_Hlk106813384"></span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">3. </span><span style="font-family:仿宋; font-size:12pt">乙</span><span
          style="font-family:仿宋; font-size:12pt">方保证向</span><span style="font-family:仿宋; font-size:12pt">甲</span><span
          style="font-family:仿宋; font-size:12pt">方提供的上述证明文件或其他相关证明真实、合法、准确、有效，并保证上述证明文件或其他相关证明发生任何变更或更新时，及时通知</span><span
          style="font-family:仿宋; font-size:12pt">甲</span><span
          style="font-family:仿宋; font-size:12pt">方，若上述文件变更或更新导致</span><span
          style="font-family:仿宋; font-size:12pt">乙</span><span style="font-family:仿宋; font-size:12pt">方不符合</span><span
          style="font-family:仿宋; font-size:12pt">企修通</span><span
          style="font-family:仿宋; font-size:12pt">所规定入驻条件的，</span><span
          style="font-family:仿宋; font-size:12pt">甲</span><span
          style="font-family:仿宋; font-size:12pt">方有权单方全部或部分</span><span style="font-family:仿宋; font-size:12pt">停止与乙方在本协议项下的合作</span><span
          style="font-family:仿宋; font-size:12pt">，直至终止本协议。 </span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">4</span><span style="font-family:仿宋; font-size:12pt">．乙</span><span
          style="font-family:仿宋; font-size:12pt">方对其提交的证明文件或其他相应证明的真实性、合法性、准确性、有效性承担全部法律责任，若因</span><span
          style="font-family:仿宋; font-size:12pt">乙</span><span style="font-family:仿宋; font-size:12pt">方提交虚假、过期文件或未及时更新证明文件或通</span><span
          style="font-family:仿宋; font-size:12pt">知导致纠纷或被相关国家机关处罚的，由乙方独立承担全部法律责</span><span
          style="font-family:仿宋; font-size:12pt">任，如因此造成</span><span
          style="font-family:仿宋; font-size:12pt">企修通</span><span style="font-family:仿宋; font-size:12pt">（包括“</span><span
          style="font-family:仿宋; font-size:12pt">企修通</span><span
          style="font-family:仿宋; font-size:12pt">”合作方、</span><span
          style="font-family:仿宋; font-size:12pt">服务商</span><span style="font-family:仿宋; font-size:12pt">或</span><span
          style="font-family:仿宋; font-size:12pt">职员</span><span style="font-family:仿宋; font-size:12pt">）损失的，</span><span
          style="font-family:仿宋; font-size:12pt">乙</span><span
          style="font-family:仿宋; font-size:12pt">方应予以赔偿。</span><span
          style="-aw-bookmark-end:_Hlk106813280"></span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">5</span><span style="font-family:仿宋; font-size:12pt">．乙</span><span
          style="font-family:仿宋; font-size:12pt">方同意</span><span style="font-family:仿宋; font-size:12pt">甲</span><span
          style="font-family:仿宋; font-size:12pt">方对</span><span style="font-family:仿宋; font-size:12pt">乙</span><span
          style="font-family:仿宋; font-size:12pt">方提供的相关资质文</span><span style="font-family:仿宋; font-size:12pt">件及各类信息资料进行审核。甲方审</span><span
          style="font-family:仿宋; font-size:12pt">核为形式审核，审核通过并不代表乙</span><span style="font-family:仿宋; font-size:12pt">方对审核内容的真实性、合法性、准确性、及时性的确认，</span><span
          style="font-family:仿宋; font-size:12pt">乙</span><span style="font-family:仿宋; font-size:12pt">方仍须对其提交的资料的真实性、合法性、准确性、及时性等承担相应的法律责任</span><span
          style="font-family:仿宋; font-size:12pt">。</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">&#xa0;</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">二、企修通代理商清退规则</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">如果代理商有以下行为，企修通有权对行为人进行清退处理。清退是指代理商严重违规后，企修通将对此代理</span><span
          style="font-family:仿宋; font-size:12pt">商名下</span><span
          style="font-family:仿宋; font-size:12pt">所有的服务账号</span><span
          style="font-family:仿宋; font-size:12pt">禁止登陆、限制再次</span><span
          style="font-family:仿宋; font-size:12pt">合作</span><span style="font-family:仿宋; font-size:12pt">等措施。 </span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">1. </span><span
          style="font-family:仿宋; font-size:12pt">乙方不按合同约定结款期支付甲方费用；</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">2</span><span style="font-family:仿宋; font-size:12pt">. </span><span
          style="font-family:仿宋; font-size:12pt">乙方恶意诋毁甲方企业名声或形象，造成恶劣影响；</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">3</span><span
          style="font-family:仿宋; font-size:12pt">. 骗取他人财物：指</span><span
          style="font-family:仿宋; font-size:12pt">代理</span><span style="font-family:仿宋; font-size:12pt">商通过</span><span
          style="font-family:仿宋; font-size:12pt">企修通</span><span
          style="font-family:仿宋; font-size:12pt">以非法获利为目的，非法获取他人财物</span><span
          style="font-family:仿宋; font-size:12pt">；</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">4</span><span style="font-family:仿宋; font-size:12pt">. 未经授权获取、使用信息：是指以不正当手段获取、未经允许发布、传递他人隐私信息，涉嫌侵犯他人隐私权的行为</span><span
          style="font-family:仿宋; font-size:12pt">；</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">5</span><span
          style="font-family:仿宋; font-size:12pt">. 资质造假：向</span><span
          style="font-family:仿宋; font-size:12pt">企修通</span><span
          style="font-family:仿宋; font-size:12pt">提供非真实资质证书、案例等 </span><span
          style="font-family:仿宋; font-size:12pt">；</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">6</span><span
          style="font-family:仿宋; font-size:12pt">. 行贿、受贿、索贿：存在向</span><span
          style="font-family:仿宋; font-size:12pt">企修通工</span><span style="font-family:仿宋; font-size:12pt">作人员或其他会员进行行贿、受贿、索贿行为 </span><span
          style="font-family:仿宋; font-size:12pt">；</span></p>
      <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">7</span><span style="font-family:仿宋; font-size:12pt">. 其他侵犯</span><span
          style="font-family:仿宋; font-size:12pt">企修通</span><span style="font-family:仿宋; font-size:12pt">、</span><span
          style="font-family:仿宋; font-size:12pt">客户</span><span
          style="font-family:仿宋; font-size:12pt">权益或第三方合法权益的行</span><span
          style="font-family:仿宋; font-size:12pt">为。</span></p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
          style="font-family:仿宋; font-size:12pt">8</span><span
          style="font-family:仿宋; font-size:12pt">. </span><span
          style="font-family:仿宋; font-size:12pt">乙方侵害甲方商誉的其他行为。</span></p>
    </div>
    <div style="line-height: 30px;cursor: pointer;color: blue"><a href="/word/企修通代理商手册.docx">企修通代理商手册附件下载</a></div>
  </div>
</template>

<script>
export default {
  name: "manual"
}
</script>

<style scoped>

</style>
