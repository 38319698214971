<template>
    <div style="margin: 30px;line-height: 30px;font-family: '宋体'">
        <p>
        <p class="MsoNormal" style="text-align: center">
           <b>企修通平台用户行为规范</b>
        </p>
        <p class="MsoNormal">
            <br />
        </p>
        <p class="MsoListParagraph" style="text-align:left;margin-left:21.25pt;text-indent:-21.25pt;">
            <b>1&nbsp;&nbsp;&nbsp; </b><b>总则<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            1.1&nbsp; 为保障企修通平台用户合法权益，维护平台正常运行秩序，根据国家相关法律法规及《企修通平台（服务商<span>/</span>渠道商）合作协议》制定本规则。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            1.2&nbsp; 本规则是企修通平台用户在企修通平台进行业务交易的基本原则，是保证平台用户有序交易的根本准则。该规则的部分内容可能存在限制用户权利或增加用户基本义务的情形，企修通平台会以显著的方式进行标注以提醒用户注意。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            1.3&nbsp; 所有用户在使用企修通平台服务时，应当遵守本规则内容，否则将无法使用企修通平台服务。一旦选择注册<span>/</span>登录使用企修通平台，即视为用户对本规则所有内容无异议，该等内容包括但不限于企修通平台以显著标记方式提请用户予以特别关注的内容。<u></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            1.4&nbsp; <u>企修通平台经营者有权修改本规则并在网站予以公告。自公告之日起，若用户不同意相关修改的，应当立即停止使用企修通平台的相关服务；若继续使用的，则视为接受修改后的规则。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            1.5&nbsp; <u>用户在企修通平台上的全部行为仅代表其个人及<span>/</span>或其企业，不代表企修通平台，基于该行为所产生的全部法律责任由用户自行承担。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            1.6&nbsp; 用户应当遵守国家法律、法规、规章的规定。<u>对任何涉嫌违反法律、法规、规章的行为，本规则已有规定的，适用本规则；本规则尚无规定的，企修通平台经营者依照相关规则或法律法规酌情处理；严重者，企修通平台经营者有权提交司法机关处理。但企修通平台经营者对用户的处理不免除用户应当承担的法律责任。</u><span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            1.7&nbsp; <u>违规行为的认定与处理，应基于交易双方向企修通平台阐述的事实及提交的佐证材料，以及企修通平台通过其他途径收集的证据材料予以认定，一旦用户违规行为被认定，则企修通平台有权根据认定的事实严格依规实施违规处理措施。企修通平台用户在适用本规则上一律平等。</u>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            1.8&nbsp; <u>企修通平台经营者有权对用户行为及适用的规则进行单方认定，并据此处理。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            1.9&nbsp; 本规则是企修通平台用户使用企修通平台服务的基本原则，是保证平台秩序的根本准则，对所有使用企修通平台服务的用户一律平等。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:.3pt;text-indent:0cm;">
            <br />
        </p>
        <p class="MsoListParagraph" style="margin-left:.3pt;text-indent:0cm;">
            <br />
        </p>
        <p class="MsoListParagraph" style="margin-left:.3pt;text-indent:0cm;">
            <br />
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>2&nbsp;&nbsp;&nbsp; </b><b>定义<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            本规范所用术语，如有其他企修通平台规则予以定义的，则援引其定义，如无定义的，则应当具有以下定义：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            2.1 企修通平台：指运营主体为重庆企修通网络科技有限公司，为用户提供交易的网络平台，平台网址<span>http://www.matefix.cn/</span>；以及企修通小程序：企修通工程师端和企修通用户端。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            2.2 企修通平台用户（简称“用户”）：指在企修通平台合法注册，接受并认可企修通平台规则的自然人、法人或非法人组织，包括渠道商、服务商、工程师、客户、用户。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            渠道商：指在企修通平台拥有对应区域企业资源的企业。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            服务商：指在企修通平台进行维保自营，直接对客户进行维保、租赁服务的企业。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            工程师：指企修通平台的服务商的维修员。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            客户：指在企修通平台有维保、租赁需求的企业。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            合作企业：指使用企修通平台服务的渠道商、服务商。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            2.3 开户：企修通合作企业注册完成，依据平台开户流程和要求进行开通账户的过程。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            2.4 交易：指合作企业通过企修通平台进行维修、保养、租赁、全包办公物资服务。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            2.5 交易模式：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            一、维修：指客户和服务商通过企修通平台进行办公物资维修服务的交易模式。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            二、保养：指客户和服务商通过企修通平台进行办公物资保养服务的交易模式。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            三、租赁：指客户和服务商通过企修通平台进行办公物资租赁服务的交易模式。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            四、全包：指客户和服务商通过企修通平台进行办公物资全包服务的交易模式。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            <br />
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <b>3&nbsp;&nbsp; </b><b>交易<span></span></b>
        </p>
        <p class="MsoListParagraph">
            <b>3.1 </b><b>注册<span></span></b>
        </p>
        <p class="MsoListParagraph">
            注册是按照企修通平台系统设置的注册流程，提交申请成为企修通平台用户的行为。<span></span>
        </p>
        <p class="MsoListParagraph">
            注册应满足的条件：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            一、同意企修通平台发布的规则与《企修通平台用户行为规范》；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            二、按要求填写相关信息，不得违反法律法规的规定。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            三、用户不得以任何形式擅自转让或授权他人使用自己在本平台的用户帐号。<span></span>
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <br />
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <br />
        </p>
        <p class="MsoListParagraph" style="text-indent:21.1pt;">
            <b>3.2 </b><b>合作企业开户<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            合作企业可按照企修通系统设置的流程开设账户，但必须通过企修通平台提供（企业营业执照副本复印件、相关资质证件等）真实有效的信息；同一主体在一个终端只能申请开通一个账号，企业名称不可重复。<span></span>
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <b>4&nbsp;&nbsp; </b><b>市场管理<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:-21.0pt;">
            &nbsp;&nbsp;&nbsp; 4.1 用户须按照企修通平台认证要求，提供企业真实有效的信息，提供的信息需是真实性、有效性、一致性的信息；对于信息不全、无效或虚假的，将无法通过认证。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            4.2 为保障用户认证信息的持续真实有效，维护用户权益，企修通平台将视情况通过定期或不定期复核的方式，验证合作企业信息的真实有效性。如在复核过程中发现提供的信息不全、无效、虚假的、未及时更新的，企修通平台将依据情形严重程度，采取限制维修、保养、租赁等临时性的市场监管措施。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.0pt;text-indent:0cm;">
            4.3 因合作企业行为引发其他用户或第三方诉讼至司法机关的，若合作企业怠于处置、消极应对，或继续违规等情形，对平台造成或可能造成实际损失或不良影响的，企修通平台可根据产生或可能产生后果的严重程度采取账户限制经营（包括但不限于限制维修、保养、租赁等）的临时性市场管控措施。<span></span>
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <b>5&nbsp;&nbsp; </b><b>用户行为<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:0cm;">
            <b>5.1 </b><b>用户账户获取、账户管理及账户安全行为<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            用户通过合法途径获得所属账号后，用户应采取合理措施维护其密码和账号的安全。用户对利用该密码和账号所进行的一切活动负全部责任；由该等活动所导致的任何损失或损害由用户承担，企修通平台经营者不承担任何责任。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            用户关于账户的各类相关行为，应当遵守《企修通平台账户管理规范》的相关规定。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:0cm;">
            <b>5.2 </b><b>用户应当遵守的法律法规<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            用户同意遵守《中华人民共和国保守国家秘密法》、《中华人民共和国著作权法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《互联网电子公告服务管理规定》、《信息网络传播权保护条例》等有关计算机及互联网规定的法律、法规、实施办法。在任何情况下，企修通平台经营者合理地认为用户的行为可能违反上述法律、法规，可以在任何时候，不经事先通知终止向该用户提供服务。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            除上述法律法规外，用户还应当同意遵守企修通平台经营者已公示或在未来公示的各项企修通平台规则，包括但不限于协议、规范、说明、声明等。如用户不同意，应当立即停止使用企修通平台。<span></span>
        </p>
        <p class="MsoListParagraph" style="text-indent:21.1pt;">
            <b>5.3 </b><b>用户发布平台信息<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            平台信息发布者使用企修通平台发布信息，应当确保自身对信息内容拥有合法权益，或已取得合法权益。如若未拥有合法权益或未取得合法授权发布平台信息，而造成的损失，由平台信息发布者自行承担。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            平台信息发布者应当确保发布的任何信息都是合法合规的，真实有效的，有关于企业经营的相关信息，是经所在企业授权许可发布的。用户发布的信息，禁止包括以下内容：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            1.&nbsp;&nbsp; <u>反对宪法确定的基本原则的；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            2.&nbsp;&nbsp; <u>危害国家统一、主权和领土完整，颠覆国家政权的；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            3.&nbsp;&nbsp; <u>泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            4.&nbsp;&nbsp; <u>煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            5.&nbsp;&nbsp; <u>破坏国家宗教政策，宣扬邪教、迷信的；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            6.&nbsp;&nbsp; <u>扰乱社会秩序，破坏社会稳定的；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            7.&nbsp;&nbsp; <u>诱导未成年人违法犯罪和渲染及传播淫秽、色情、赌博、暴力、凶杀、恐怖活动或者教唆犯罪的；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            8.&nbsp;&nbsp; <u>侮辱或者诽谤他人<span>/</span>组织，侵害公民个人<span>/</span>组织隐私等他人<span>/</span>组织合法权益的；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            9.&nbsp;&nbsp; <u>散布谣言，危害社会公德，损害民族优秀文化传统的；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            10.&nbsp; <u>包含极限词汇的内容（如：“最好”“最佳”“首选”“唯一”等）<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            11.&nbsp; <u>有关法律、行政法规和国家规定禁止的其他内容； <span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            12.&nbsp; <u>未经授权使用的平台官方标签的信息（如：“官方推荐”等）<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:63.5pt;text-indent:-21.0pt;">
            13.&nbsp; <u>未经授权的企修通平台<span>LOGO\</span>文字<span>\</span>图形等<span></span></u>
        </p>
        <p class="MsoNormal" style="margin-left:21.3pt;text-indent:21.2pt;">
            <u>如果用户发布平台信息含有以上违反法律法规的信息或内容的，或者侵犯任何第三方的合法权益，用户将直接承担以上导致的一切不利后果。如因此给企修通平台造成不利后果的，用户应负责消除影响，并且赔偿企修通平台经营者因此导致的一切损失，包括且不限于财产损害赔偿、名誉损害赔偿、律师费、交通费等因维权而产生的合理费用。</u>
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <b>6&nbsp;&nbsp; </b><b>违规行为与处理<span></span></b>
        </p>
        <p class="MsoListParagraph" style="text-indent:21.1pt;">
            <b>6.1 </b><b>一般违规行为<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            用户理解并保证不就企修通平台服务进行下列的禁止的行为，也不允许任何人利用用户的账户进行下列行为：<b><span></span></b>
        </p>
        <p class="MsoListParagraph">
            <u>1. </u><u>指合作企业提供与其营业执照范围不相关的服务。<span></span></u>
        </p>
        <p class="MsoListParagraph">
            <u>2. </u><u>指合作企业发布与平台交易无关的信息。<span></span></u>
        </p>
        <p class="MsoListParagraph">
            <u>3. </u><u>指经查实，合作企业存在大量刷单的行为。<span></span></u>
        </p>
        <p class="MsoListParagraph">
            <u>4. </u><u>无理要求：指合作企业提出超出合同约定、额外的不合理的要求。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>5. </u><u>用户提供的联系方式无效，或怠于处置、消极应对、拒不配合处理订单或提交相关资料等情形。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>6. </u><u>拒绝提供已承诺服务：指合作企业无理由拒绝为客户提供其承诺的服务。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>7. </u><u>发票违规：未按照平台要求开具发票的行为。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>8. </u><u>虚假交易：虚假交易（刷单）指合作企业通过虚构或隐瞒交易事实、利用或规避信用记录规则、干扰或妨害信用记录秩序等不正当方式获取虚假的服务销量、工程师评分等不当利益，破坏正常交易秩序的行为。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>9. </u><u>线下交易：客户与合作企业交易过程中，引导或接受合作企业在企修通之外进行交易。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>10. </u><u>引流交易：合作企业不得引导或引流可客户到其他平台增加访问流量的。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>11. </u><u>恶意评价：指同行竞争者等利益相关评价人以给予中、差评的方式以达到不当目的。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>12. </u><u>恶意抄袭：指平台上同行经营者为了不正当竞争，恶意抄袭，从而引起用户混淆，导致被抄袭方遭受订单损失、流量损失的。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>13. </u><u>恶意举报：用户通过举报干扰他人正常交易的行为。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>14. </u><u>虚假举证：指当任意一方接到举报或纠纷时，假冒对方向企修通平台表示撤销投诉；或虚构事实，隐瞒实情，阻碍企修通平台正常调查的行为。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>15. </u><u>虚假认证：指个人用户或企业用户使用虚假资质或虚假信息入驻企修通平台的行为。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:0cm;">
            <u>虚假认证包含但不限于以下情形：<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>（一）盗用、冒用他人的身份信息、企业工商信息、行政许可信息等用于通过网站的注册、认证环节；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>（二）伪造、变造他人的身份信息、企业工商信息、行政许可信息等用于通过网站的注册、认证环节；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>（三）身份信息、企业工商信息、行政许可信息等发生变更，未及时更新，或经提醒仍不更新的情形；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;text-indent:-10.5pt;">
            <u>16. </u><u>骚扰他人：指用户通过隐私小号、钉钉、邮箱、<span>QQ</span>、微信等任何方式向他人实施骚扰，发送垃圾信息、频繁地联系他人，或多次在深夜、凌晨等不适宜交流的时间段内进行联系，影响他人正常生活的行为<span>,</span>影响他人合法权益的行为。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:31.5pt;">
            <u>情节严重包括但不限于：多次出现骚扰行为或严重影响他人正常生活，造成严重恶劣影响的行为，如寄送让人产生反感、恐惧的物品等。<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            17. <u>在获取账户时，或使用企修通平台服务（包括但不限于维修、保养、租赁等）时，<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>冒充他人或机构，或讹称与任何人或实体有联系（包括设置失实的账户名称或接入另一用户的账户），或恶意使用注册账户导致其他用户误认；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            18. <u>用户未经允许泄露企修通平台内容（客户资料、内部资料、机密资料、侵犯任何人的专利、商标、商业秘密或其他专属权利之内容等）进行上传、发布、发送电子邮件或以其他方式传输；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            19. <u>未经企修通平台经营者明确许可，使用企修通平台服务用于任何商业用途或为任何第三方的利益；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            20. <u>参与任何非法或有可能非法（由企修通平台经营者判断）的活动或交易，包括传授犯罪方法、出售任何非法药物、洗钱活动、诈骗等；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            21. <u>赌博、提供赌博数据或透过任何方法诱使他人参与赌博活动；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            22. <u>使用或利用企修通平台知识产权（包括但不限于企修通平台的商标、品牌、标志、企修通渠道商端、企修通服务商端、企修通客户端、企修通工程师端、企修通用户端、企修通管理后台、企修通设备码、任何其他专有数据或任何网页的布局或设计），或在其他方面侵犯企修通平台任何知识产权（包括试图对企修通平台客户端或所使用的软件进行逆向工程）；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            23. <u>通过使用任何自动化程序、软件、引擎、网络爬虫、网页分析工具、数据挖掘工具或类似工具，接入企修通平台服务、收集或处理通过企修通平台服务所提供的内容；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            24. <u>参与任何“框架”、“镜像”或其他技术，目的是模仿企修通平台服务的外观和功能（包括但不限于企修通平台的商标、品牌、标志、企修通渠道商端、企修通服务商端、企修通客户端、企修通工程师端、企修通用户端、企修通管理后台、企修通设备码、任何其他专有数据或任何网页的布局或设计）；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            25. <u>干预或试图干预任何用户或任何其他方接入企修通平台服务；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            26. <u>故意散播含有干扰、破坏或限制计算机软件、硬件或通讯设备功能、企修通平台服务、与企修通平台服务相连的服务器和网络的病毒、网络蠕虫、特洛伊木马病毒、已损毁的档案或其他恶意代码或项目等资料；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            27. <u>未经他人明确同意，分享或发布该等人士（或企业）可识别身份的资料；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            28. <u>探究或测试企修通平台服务、系统或其他用户的系统是否容易入侵攻击，或在其他方面规避（或试图规避）企修通平台服务、系统或其他用户的系统的任何安全功能；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            29. <u>对企修通平台服务所用的软件进行解编、反向编译或逆向工程，或试图作出上述事项；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            30. <u>为破坏或滥用的目的创建维保订单，或恶意上传重复的、无效的订单；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            31. <u>进行任何破坏企修通平台服务公平性或者其他影响应用正常秩序的行为，如主动或被动刷分、合伙作弊、使用外挂或者其他的作弊软件、利用<span>BUG</span>（又叫“漏洞”或者“缺陷”）来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊软件、<span>BUG</span>公之于众<span>.</span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            32. <u>通过任何渠道或媒体（包括但不限于自媒体等）发出“与企修通平台合作”、“与企修通平台共同出品”等任何携带“企修通”品牌的字样，如您宣传推广合作节目，您只能在宣传中提及节目本身而不得提及与企修通平台关系或者擅自以企修通平台品牌进行推广，凡是您的发稿带有“企修通平台”的一切宣传稿件必须通过企修通平台相应合作部门之书面同意，否则因此给企修通平台造成的一切损失您应予以赔偿；<span></span></u>
        </p>
        <p class="MsoListParagraph" style="margin-left:0cm;">
            <b>33. </b><u>故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范。</u><b></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:0cm;">
            <b>6.2 </b><b>违规处理措施<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            用户在企修通平台上发布的内容和信息构成违约的，企修通平台经营者可根据相应规则立即对相应内容和信息进行删除、屏蔽等处理或对用户的账户进行暂停使用、查封、冻结、注销等处理。
            用户在企修通平台上实施的行为，或虽未在企修通平台上实施但对企修通平台及其用户产生影响的行为构成违约的，企修通可依据相应规则对用户中止提供部分或全部服务等处理措施。如用户的行为构成根本违规的，企修通可查封用户的账户，终止提供服务。如用户的账户被查封，账户内的虚拟产品将被清空，相关虚拟产品服务将被终止提供。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            如果用户在企修通平台上的行为违反相关的法律法规，企修通平台经营者可依法向相关主管机关报告并提交用户的使用记录和其他信息。同时，企修通平台经营者可将对用户上述违规行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在企修通平台上予以公示。除此之外，企修通平台经营者可依据国家相关法律法规规定，对用户进行黑名单管理和信用管理，提供与信用等级挂钩的管理和服务，若用户被纳入黑名单，有权对纳入黑名单的用户采取禁止重新注册帐号的措施，并及时向相关部门报告。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:0cm;">
            <b>6.3 </b><b>赔偿责任<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            如用户的行为使企修通平台经营者及<span>/</span>或其关联公司遭受损失（包括但不限于自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），用户应赔偿企修通平台经营者及<span>/</span>或其关联公司的上述全部损失。 <u>如用户的行为使企修通平台经营者及<span>/</span>或其关联公司遭受第三人主张权利，企修通平台经营者及<span>/</span>或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。<b><span></span></b></u>
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <b>7&nbsp;&nbsp; </b><b>规则的变更及通知<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            企修通平台经营者可根据国家法律法规变化、企修通平台商业模式的调整及维护交易秩序、保护消费者权益需要，不时修改本规则、补充规则，变更后的规则、补充规则（下称“变更事项”）将通过法定程序并以《企修通平台（服务商<span>/</span>渠道商）合作协议》中的约定方式通知您。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.1pt;">
            <b><u>如您不同意变更事项，您有权于变更事项确定的生效日前联系企修通平台经营者反馈意见。如反馈意见得以采纳，企修通平台经营者将酌情调整变更事项。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用企修通平台服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用企修通平台服务，则视为您同意已生效的变更事项。<span></span>
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <b>8&nbsp;&nbsp; </b><b>法律适用、管辖与其他<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            本规则之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及<span>/</span>或行业惯例。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            您因使用平台服务所产生及与平台服务有关的争议，由平台经营者与您协商解决。协商不成时，您同意将纠纷或争议提交至平台经营者所在地有管辖权的司法机构管辖。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:21.2pt;">
            本规则任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本规则其余条款的有效性及可执行性。<span></span>
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <b><span>9&nbsp;&nbsp; </span></b><b>附则<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            <span>9.1&nbsp; </span>本制度自发布之日起执行。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:0cm;">
            <span>9.2&nbsp; </span>本制度修订权及最终解释权归企修通所有。<span></span>
        </p>
        <p class="MsoListParagraph" align="right" style="margin-left:21.3pt;text-align:right;text-indent:0cm;">
            重庆企修通网络科技有限公司<span></span>
        </p>
        <p class="MsoNormal">
            <b></b>
        </p>
        </p>
        <p>
            <br />
        </p>
        <div style="line-height: 30px;cursor: pointer;color: blue"><a href="/word/企修通平台用户行为规范.docx">企修通平台用户行为规范附件下载</a></div>
    </div>
</template>

<script>
    export default {
        name: "UserCodeConduct"
    }
</script>

<style scoped>

</style>
