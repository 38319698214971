<template>
    <div class="xieyi" style="margin: 30px;line-height: 30px">
        <p>
            <br />
        </p>
        <p class="MsoNormal" align="center" style="margin-left:0cm;text-align:center;">
            <b>企修通平台服务协议<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>提示条款<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:1.0cm;">
            欢迎您与企修通平台经营者签订《企修通平台服务协议》，并使用企修通平台相关服务。各条款标题仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:1.0cm;">
            自本协议公布之日及修订后公布之日起，企修通平台各处所称“平台服务协议”均指本协议。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:1.0cm;">
            您在使用企修通平台或选择同意本协议之前，应当认真阅读本协议。<b><u>请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读（如果您是未成年人，您应在法定监护人陪同下完成）。</u></b><span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:1.0cm;">
            当您选择同意本协议及<span>/</span>或完成注册程序、使用企修通平台服务后，即表示您已充分阅读、理解并接受本协议的全部内容，并与企修通平台经营者达成一致，成为平台注册用户。<b><u>在您阅读本协议的过程中，如果您不同意本协议或其中任一条款约定，您应立即退出并停止使用本平台服务。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>定义<span></span></b>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>企修通平台：</b>指企修通官网（移动端、<span>PC</span>端），包含企修通客户端、企修通服务商端、企修通渠道商端；企修通用户端（微信小程序）、企修通工程师端（微信小程序）。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>企修通平台服务：</b>指企修通平台基于互联网，以包含企修通平台官网、用户端、工程师端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>企修通平台经营者：</b>指重庆企修通网络科技有限公司及其关联方。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>企修通平台规则：</b>指企修通平台经营者在企修通平台内已经公示及<span>/</span>或未来将公示的各类政策、协议、解读、规则等。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:-.2pt;">
            <b>用户：</b>下称“您”或“用户”，是指注册、登录、使用、浏览、获取本协议项下服务的个人或组织。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>协议范围<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>签约主体<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            本协议由您与企修通平台经营者共同缔结，本协议对您与企修通平台经营者均具有合同效力。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            企修通经营者是指经营企修通平台的法律主体，您可随时查看平台公示的证照信息以确定与您履约的法律主体。<b><u>本协议项下，平台经营者可能根据平台的业务调整而发生变更，变更后的平台经营者与您共同履行本协议并向您提供服务，平台经营者的变更不会影响您本协议项下的权益。平台经营者还有可能因为提供新的服务而新增，如您使用新增平台服务的，视为您同意新增的平台经营者与您共同履行本协议。发生争议时，您可根据您具体使用的服务及对您权益产生影响的具体行为对象，确定与您履约的主体及争议相对方。</u></b><span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>补充协议<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            <b><u>由于互联网高速发展，您与平台经营者签署的本协议列明的条款并不能完整罗列并覆盖您与平台经营者的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，平台各项法律声明<span>/</span>政策、知识产权声明、隐私保护政策等在平台及时公布的相关文件均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用平台服务，视为您同意上述补充协议。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>账户注册、使用与注销<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>用户资格<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            您确认，在您开始注册使用平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，具体是<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.1.1&nbsp;&nbsp;&nbsp; </b>年满十八岁具备完全民事权利能力和民事行为能力的自然人；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>4.1.2&nbsp;&nbsp;&nbsp; </b>据中国法律法规成立并合法存在的机关、企事业单位、社团组织和其他组织。<b><span></span></b>
        </p>
        <p class="MsoNormal" style="margin-left:21.3pt;text-indent:21.0pt;">
            <b><u>若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            此外，<b><u>您还需遵从国家相关法律法规，并确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用平台服务。</u></b><span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>账户说明<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            您应当确保账户是由您本人与相关企修通对接人进行申请开户流程，而非冒用他人信息或未经合法授权以及其他非法方式进行注册获得。当您按照页面提示完善信息、阅读并同意本协议且完成全部注册程序后，您可获得平台账户并成为平台用户。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            平台账户的所有权归平台经营者所有，您完成申请注册手续后，获得平台账户的使用权，该使用权仅属于初始申请注册人。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            您有权使用您设置或确认的座机、邮箱、手机号码（以下简称“账号”）及您设置的密码（账号及密码合称“账户”）登录平台，由于您的账户关联您的重要信息，您的账户仅限您本人使用。您应当对该账户的一切行为负责。如平台经营者根据平台协议中约定的违约认定程序及标准，判断您账户的使用可能危及您的账户安全及<span>/</span>或平台信息安全的，平台经营者可拒绝提供相应服务或终止本协议。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            由于用户账户关联用户重要信息，仅当有法律明文规定、司法裁定或其他合法的情况下，您可进行账户的转让（<b><u>该账户项下权利义务随账户转让一并转移</u></b>），除此之外的情况，您不被允许进行账户转让，<b><u>否则平台经营者有权追究您的违约责任，且由此产生的一切责任均由您承担。</u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            作为平台经营者，为使您更好地使用平台的各项服务，保障您的账户安全，平台经营者可以要求您按平台规则要求及我国法律规定完成实名认证。<b><u>如您未进行实名认证的，有可能影响到您正常使用平台的各项服务和权益保障。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            账户因所属人不明的情况产生纠纷或其他因司法行政等国家公职部门工作要求的情况下，平台经营者有权对账户进行锁定处理。因纠纷情况锁定导致的任何损失及责任由发生纠纷的双方共同承担。<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>账户信息管理<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            在使用平台服务时，您应当按平台页面的提示准确完整地提供您的信息，以便平台经营者或其他用户与您联系。<b><u>您了解并同意，您有义务保持您提供信息的真实性及有效性。</u></b>如您未提供准确完整的信息，将影响到您正常使用平台的各项服务和权益保障。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            <b><u>您理解并承诺您设置的平台账户名称必须是本人真实姓名，不得违反国家法律法规，没有冒用、关联机构或社会名人，您在账户注册过程中需遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，否则平台经营者可回收您的平台账户名称。您账户名称的回收不影响您以邮箱、手机号码、座机登录平台并使用平台服务</u></b>。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            服务商注册账号的企业名字不可重复，如平台经营者发现您注册或使用的企业账户等一切自定义名称与其他账户相同而导致无法识别，平台经营者有权要求您修改上述名称，如您在平台经营者要求的时限内未予修改，则平台经营者有权在您自定义的名称后加注识别符号予以区别以确保软件正常运行（例如您希望或正在使用的账户名称为“企修通”，但在同一组服务器中同样存在另外一个用户账户名为“企修通”，则在您不愿意修改名称的情况下，平台经营者有权不经您同意的情况在您名称后加注识别符号后成为“企修通<span>1”</span>、<span>“</span>企修通<span>2”</span>等），您保证无条件同意上述修改。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            您应当及时更新您提供的信息，在法律有明确规定要求平台经营者必须对部分用户的信息进行核实的情况下，平台经营者将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            <b><u>如平台经营者按您最后一次提供的信息与您联系未果、您未按要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及平台造成的全部损失与不利后果。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            <b><u>平台经营者可向您发出询问或要求整改的通知，并要求您进行重新提供及认证，直至中止、终止对您提供部分或全部平台服务，如您未能及时处理，平台经营者对因此造成的损失不承担任何责任。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            您可以对部分自身账户相关信息进行查询、更新、删除。平台提供部分自助查询、更新、删除信息的功能。同时您也可通过联系平台经营者请求提供相关信息的查询、更新、删除服务。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>4.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>账户安全规范<span></span></b>
        </p>
        <p class="MsoNormal" style="margin-left:21.3pt;text-indent:21.0pt;">
            您的账户为您自行设置并由您保管，平台经营者任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开平台。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.3pt;text-indent:21.0pt;">
            账户因您自身原因泄露或因您遭受他人攻击（含您使用的计算机被他人侵入）、诈骗等行为导致的损失及后果，平台并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.3pt;text-indent:21.0pt;">
            鉴于网络服务的特殊性，平台经营者不审核是否为您本人使用您的账户及密码，仅审核账户及密码是否与数据库中保存的一致，只要任何人输入的账户及密码与数据库中保存的一致，即可凭借该组账户及密码登陆平台。若使用者并非账户初始申请注册人，平台经营者有权在未经通知的情况下冻结、回收该账户且无需向该账户使用人承担任何法律责任，由此导致的包括并不限于您通讯中断、用户资料和虚拟道具等清空等损失由您自行承担。若账户的归属出现争议的，平台经营者在收到相关方的投诉后，有权暂时冻结该争议账户；争议各方在合理举证期限内提供证据证明账户归属，平台经营者依据各方提供的证据判断归属后，解冻争议账户。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.3pt;text-indent:21.0pt;">
            如发现任何未经授权使用您账户登录平台或其他可能导致您账户遭窃、遗失的情况，建议您立即通知平台经营者，并授权平台经营者进行处理。您理解平台经营者对您的任何请求采取行动均需要合理时间，且平台经营者应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除存在法定过错外，平台经营者将不承担责任。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.3pt;text-indent:21.0pt;">
            除平台存在过错外，您应对您账户项下的所有行为结果负责。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>4.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>账户注销<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:42.55pt;text-indent:0cm;">
            <b><span>4.5.1 </span>企业账号的注销<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:42.55pt;">
            由于企修通平台的特殊性，企业账户的注销必须严格遵守《企修通平台退出规则》并书面形式向企修通平台经营者提前<span>15</span>个工作日进行通知。用户提出注销申请后并满足《企修通平台退出规则》条件后，企修通平台经营者会根据《企修通平台退出规则》相关流程注销企业账户。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:42.55pt;">
            企业账户注销后，该企业账户下的所有子账户将无法继续使用。企修通平台经营者将遵照平台规则进行相关数据的删除或隐匿处理。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:42.55pt;text-indent:0cm;">
            <b><span>4.5.2 </span>子账号的注销<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:42.55pt;">
            由于企修通平台用户账户设立的特殊性，子账号仅能通过其所在企业的主账号进行账户注销的操作。但企修通平台仍保留相关的渠道（包括但不限于线上程序、客服电话等）协助您进行账户的注销，您也可以通过企修通平台官方网站（<span>www.matefix.cn</span>）联系平台经营者进行处理。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:42.1pt;">
            您可以请求平台经营者提供注销账户服务或通过平台提供的服务窗口自行进行账户注销。如您注销账户，请自行妥善处理资金、票据、重要信息等事宜。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>信息的保护与授权<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>用户个人信息的保护与授权<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;text-indent:21.1pt;">
            平台经营者非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用平台服务时，您同意平台经营者按照《隐私保护政策》收集、存储、使用、披露和保护您的个人信息。平台经营者希望通过《隐私保护政策》向您清楚地介绍平台经营者对您个人信息的处理方式，因此平台经营者建议您完整地阅读隐私保护政策，以帮助您更好地保护您的隐私。<span></span>
        </p>
        <p class="MsoNormal" align="left" style="margin-left:21.2pt;text-align:left;text-indent:21.1pt;background:white;">
            <b><u>如您拒绝提供平台服务所需要的信息，将影响到平台向您提供正常服务。包括但不限于无法登陆平台，无法开展业务和其他需要相应信息支持的服务。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" align="left" style="margin-left:49.6pt;text-align:left;text-indent:-1.0cm;background:white;">
            <b>5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>用户非个人信息的保证与授权<u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;text-indent:21.1pt;">
            您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，平台经营者可对您发布的信息依法或依本协议进行删除或屏蔽。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;text-indent:21.1pt;">
            您应当确保您所发布的信息不包含以下内容：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            5.2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            违反国家法律法规禁止性规定的；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            5.2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            5.2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            欺诈、虚假、不准确或存在误导性的；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            5.2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            5.2.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            5.2.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            存在可能破坏、篡改、删除、影响平台任何系统正常运行或未经授权秘密获取平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            5.2.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            其他违背社会公共利益或公共道德或依据相关平台协议、规则的规定不适合在平台上发布的。<span></span>
        </p>
        <p class="MsoNormal" style="margin-left:21.2pt;text-indent:21.3pt;">
            除非有相反证明，<b><u>您使用平台服务上传、发布或传输内容即代表了您有权且同意，将您在平台上传发布或传输的全部内容的全部知识产权（包括但不限于著作权、商标权等知识产权以及相关的一切衍生权利）及相关权益，自上传、发布或传输之日起即授权给企修通平台在全球范围内、不可撤销的、免费使用。协议期内及协议期满后，企修通平台可以任何方式使用并享有相应的收益，未经企修通经营者事先书面同意，您不得自行或提供、授权給任何第三方以任何方式使用（包括但不限于在视频平台、直播平台等其他任何第三方平台发布）及获得任何收益。</u></b><span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>平台信息保护</b><span></span>
        </p>
        <p class="MsoNormal" align="left" style="margin-left:21.2pt;text-align:left;text-indent:21.0pt;background:white;">
            <b><u>未经平台经营者书面允许，您不得将平台的任何资料以及在平台上所展示的任何信息作商业性利用（包括但不限于以复制、修改、翻译等形式制作衍生作品、分发或公开展示）。&emsp;<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>平台服务<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>平台服务内容<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:0cm;">
            企修通平台向您提供包括但不限于以下的服务：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.1.1&nbsp;&nbsp;&nbsp; </b>企修通平台经营者直接所有及<span>/</span>或经营的网站；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.1.2&nbsp;&nbsp;&nbsp; </b>企修通平台经营者直接所有及<span>/</span>或经营的小程序；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.1.3&nbsp;&nbsp;&nbsp; </b>企修通平台内搜索、筛选、分类服务；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.1.4&nbsp;&nbsp;&nbsp; </b>企修通平台维修、保养、租赁、全包服务；<b><span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>平台服务规范<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;">
            <b><u>企修通平台服务，均仅限于您在企修通平台使用，任何以恶意破解等非法手段将企修通平台服务与企修通平台分离的行为，均不属于本协议中约定的企修通平台服务。由此引起的一切法律后果由行为人负责，企修通将依法追究行为人的法律责任。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            <b><u>企修通平台官方公布的方式为注册、登录、下载、使用企修通平台服务的唯一合法方式，您通过其他任何渠道、任何途径、任何方式获取的企修通平台服务（包括但不限于账户、产品信息、企业信息、下载等）均为非法取得，企修通平台经营者概不承认其效力，且一经发现企修通平台经营者有权立即做出删除、取消、清零、封号等处理，任何因此导致的一切不利后果均由您自行承担，企修通平台经营者有权依法追究相应人员或机构的法律责任。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            <b><u>如您在征得企修通平台经营者同意情况下利用平台实施推广等商业行为和营销内容的，您必须在国家相关法律法规及企修通平台关于推广等商业行为规范的基础上进行。您的商业行为和营销内容，必须本着平等、公平原则进行合理竞争，必须本真着真实准确原则进行合理推广，必须本着保护用户体验原则选择合理的推广方式及营销内容。如您进行恶意竞争，或采取虚假的商业行为、发布虚假夸大的营销内容，或发布频繁的、低质量的营销类信息，或您发布国家相关法规明令禁止的广告内容、违规开展商业行为等，平台经营者有权将其内容的传播范围、传播对象作出限制，限制或禁止您使用平台全部或部分服务、注销账户以及依据法律法规保存有关信息并向有关部门报告等。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            <b><u>您理解并知晓在使用平台服务时，所接触的内容和信息来源广泛，平台经营者无法对该内容和信息的准确性、真实性、可用性、安全性、完整性和正当性负责。您理解并认可您可能会接触到不正确的、令人不快的、不适当的或令人厌恶的内容和信息，您不会以此追究平台经营者的相关责任。但平台经营者有权依法停止传输任何前述内容并采取相应处理，包括但不限于暂停您继续使用平台的部分或全部服务，保存有关记录并向有关机关报告。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            <b><u>如您发布新闻信息，您理解并确认您具备互联网新闻信息服务资质，发布的新闻信息真实准确、客观公正。转载新闻信息应当完整准确，不得歪曲新闻信息内容，并在显著位置注明来源，保证新闻信息来源可追溯。平台经营者有权审核您发布的新闻信息，如您发布不实新闻或您不具备发布资质，平台经营者有权将您发布内容的传播范围、传播对象作出限制，限制或禁止您使用平台全部或部分服务、注销账户以及依据法律法规保存有关信息并向有关部门报告等。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            <b><u>平台经营者不对您在平台上传、发布或传输的任何内容和信息背书、推荐或表达观点，也不对任何内容和信息的错误、瑕疵及产生的损失或损害承担任何责任，您对内容和信息的任何使用需自行承担相关的风险。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            <b><u>您应当自行约束您在平台上的用户行为，如您在平台网站外的场所或地域，因您自身原因、不可抗力或意外事件或第三方原因造成的人身及财产损失，由您自行承担。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            使用平台服务过程中，您需遵守以下法律法规：《中华人民共和国保守国家秘密法》、《中华人民共和国著作权法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《信息网络传播权保护条例》、《互联网直播服务管理规定》等有关计算机及互联网规定的法律、法规。<b><u>在任何情况下，平台经营者一旦合理地认为您的行为可能违反上述法律、法规，可以在任何时候，不经事先通知终止向您提供服务。</u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>禁止的行为<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            <b><u>您理解并保证不就平台服务进行下列的禁止的行为，也不允许任何人利用您的账户进行下列行为：<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.1&nbsp;&nbsp;&nbsp; </b>在注册账户时，或使用平台服务（包括但不限于上传、发布、传播信息等）时，冒充他人或机构，或您讹称与任何人或实体有联系（包括设置失实的账户名称或接入另一用户的账户），或您恶意使用注册账户导致其他用户误认；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.2&nbsp;&nbsp;&nbsp; </b>伪造标题或以其他方式操控内容，使他人误认为该内容为平台所传输；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.3&nbsp;&nbsp;&nbsp; </b>将无权传输的内容（例如内部资料、机密资料、侵犯任何人的专利、商标、著作权、商业秘密或其他专属权利之内容等）进行上传、发布、发送电子邮件或以其他方式传输；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.4&nbsp;&nbsp;&nbsp; </b>上传、张贴、发送电子邮件或以其他方式传送任何未获邀约或未经授权的<span>“</span>垃圾电邮<span>”</span>、广告或宣传资料、促销资料，或任何其他商业通讯；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.5&nbsp;&nbsp;&nbsp; </b>未经平台经营者明确许可，使用平台服务用于任何商业用途或为任何第三方的利益；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.6&nbsp;&nbsp;&nbsp; </b>跟踪或以其他方式骚扰他人；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.7&nbsp;&nbsp;&nbsp; </b>参与任何非法或有可能非法（由平台经营者判断）的活动或交易，包括传授犯罪方法、出售任何非法药物、洗钱活动、诈骗等；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.8&nbsp;&nbsp;&nbsp; </b>赌博、提供赌博数据或透过任何方法诱使他人参与赌博活动；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.9&nbsp;&nbsp;&nbsp; </b>使用或利用平台知识产权（包括平台的商标、品牌、标志、任何其他专有数据或任何网页的布局或设计），或在其他方面侵犯平台任何知识产权（包括试图对平台客户端或所使用的软件进行逆向工程）；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.10</b>通过使用任何自动化程序、软件、引擎、网络爬虫、网页分析工具、数据挖掘工具或类似工具，接入平台服务、收集或处理通过平台服务所提供的内容；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.11</b>参与任何<span>“</span>框架<span>”</span>、<span>“</span>镜像<span>”</span>或其他技术，目的是模仿平台服务的外观和功能；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.12</b>干预或试图干预任何用户或任何其他方接入平台服务；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.13</b>故意散播含有干扰、破坏或限制计算机软件、硬件或通讯设备功能、平台服务、与平台服务相连的服务器和网络的病毒、网络蠕虫、特洛伊木马病毒、已损毁的档案或其他恶意代码或项目等资料；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.14</b>未经他人明确同意，分享或发布该等人士可识别个人身份的资料；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.15</b>探究或测试平台服务、系统或其他用户的系统是否容易入侵攻击，或在其他方面规避（或试图规避）平台服务、系统或其他用户的系统的任何安全功能；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.16</b>对平台服务所用的软件进行解编、反向编译或逆向工程，或试图作出上述事项；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.17</b>为破坏或滥用的目的开设多个账户，或恶意上传重复的、无效的大容量数据和信息；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.18</b>进行任何破坏平台服务公平性或者其他影响应用正常秩序的行为，如主动或被动刷分、合伙作弊、使用外挂或者其他的作弊软件、利用<span>BUG</span>（又叫<span>“</span>漏洞<span>”</span>或者<span>“</span>缺陷<span>”</span>）来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊软件、<span>BUG</span>公之于众；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.19</b>通过任何渠道或媒体（包括但不限于自媒体等）发出与平台经营者合作、与平台经营者共同出品等任何携带平台经营者品牌的字样，如您宣传推广合作节目，您只能在宣传中提及节目本身而不得提及与平台关系或者擅自以平台品牌进行推广，凡是您的发稿带有平台信息的一切宣传稿件必须通过平台经营者相应合作部门之书面同意，否则因此给平台造成的一切损失您应予以赔偿；<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>6.3.20</b>故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范。<b><u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            您可以在使用平台服务过程中购买虚拟产品增值服务。若无特殊说明，您通过在平台及其他平台经营者授权的合法渠道上获得的虚拟产品，具体使用方法、期限等以平台页面中附带的说明及用户指南或具备以上解说性质的类似官方文档为准。虚拟产品会因用户需求、网站策略调整、用户接受程度等因素随时进行调整，具体信息请以当时的页面说明为准。
            基于虚拟产品的性质和特征，平台经营者不提供虚拟产品的退货、换货服务。如您消耗所获得的全部虚拟产品，且不将继续使用虚拟产品服务的，则该服务终止。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.3pt;text-indent:21.2pt;">
            您应遵守本协议的各项条款，正确、适当地使用本服务，不得扰乱平台秩序，包括但不限于扰乱平台金融秩序等。除非得到平台经营者的书面授权，您不得将虚拟产品用于商业领域，包括但不限于买卖、置换、抵押或以特定方式使用虚拟产品服务获取不当得利等。需要特别注意的是，如您涉嫌使用不合理手段充值（包括但不限于非法使用信用卡套现）的账户，平台经营者有权依合理判断将您的账户暂时或永久封停。同时，平台经营者保留在任何时候收回该帐号、用户名的权利。 <b><u>任何用户都应通过正规渠道获得虚拟产品服务，一切通过非官方公布渠道取得的虚拟产品及其衍生服务均不对平台经营者发生法律效力，平台经营者有权单方面收回相关虚拟产品并终止相应服务，严重者平台经营者有权对中止或终止您使用平台全部或部分服务、注销账户以及依据法律法规保存有关信息并向有关部门报告等。</u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>知识产权<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            除非另有约定或企修通平台经营者另行声明，平台内的所有内容（用户依法享有版权的内容除外）、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利，均归企修通平台经营者所有。未经平台经营者许可，任何人不得擅自使用（包括但不限于复制、传播、展示、镜像、上载、下载、修改、出租）。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            企修通平台的<span>Logo</span>、文字、图形及其组合，以及平台的其他标识、徵记、产品和服务名称均为企修通平台经营者在中国或其它国家的商标，未经企修通平台经营者书面授权，任何人不得以任何方式展示、使用或作其他处理，也不得向他人表明您有权展示、使用或作其他处理。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            企修通平台经营者对平台专有内容、原创内容和其他通过授权取得的独占或独家内容享有完全知识产权。未经企修通平台经营者许可，任何单位和个人不得私自转载、传播和提供观看服务或者有其他侵犯企修通平台经营者知识产权的行为，否则将承担包括侵权等所有相关的法律责任。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>用户违约及处理<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>违约认定<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;">
            发生如下情形之一的，视为您违约：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>8.1.1&nbsp;&nbsp;&nbsp; </b>使用平台服务时违反有关法律法规规定的；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>8.1.2&nbsp;&nbsp;&nbsp; </b>违反本协议或本协议补充协议约定的<span>;</span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>8.1.3&nbsp;&nbsp;&nbsp; </b>存在用户或其他第三方通知平台经营者，认为您或具体交易事项存在违法或不当行为，并提供相关证据。平台经营者以普通非专业人员的知识水平标准对相关内容进行判别，可以明显认为这些内容或行为可能对他方、平台及<span>/</span>或平台经营者造成财务损失或承担法律责任的。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.65pt;text-indent:-1.0cm;">
            <b>8.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>违约处理措施<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;">
            您在平台上发布的信息构成违约的，<b><u>平台经营者可根据相应规则立即对相应信息进行删除、屏蔽处理或对您的信息及<span>/</span>或服务进行下架、监管。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;">
            您在平台上实施的行为，或虽未在平台上实施但对平台及其用户产生影响的行为构成违约的，<b><u>平台经营者</u></b><b><u>可依据相应规则中止向您提供部分或全部服务、进行必要处罚等处理措施。如您的行为构成根本违约的，平台经营者可查封您的账户，终止向您提供服务。如您的账户被查封，您账户内的虚拟产品将被清空，相关虚拟产品服务将被终止提供。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;">
            <b><u>如果您在平台上的行为违反相关的法律法规，平台经营者可依法向相关主管机关报告并提交您的使用记录和其他信息。同时，平台经营者可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在平台上予以公示。除此之外，平台经营者可依据国家相关法律法规规定，对您进行黑名单管理和信用管理，提供与信用等级挂钩的管理和服务，若您被纳入黑名单，有权对纳入黑名单的您采取禁止重新注册帐号的措施，并及时向相关部门报告。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>8.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>赔偿责任<u><span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;">
            <b><u>如您的行为使平台经营者遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿平台经营者的上述全部损失。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;">
            <b><u>如您的行为使平台经营者遭受第三人主张权利，平台经营者可在对第三人承担金钱给付等义务后就全部损失向您追偿。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>8.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>特别约定</b><span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;">
            如您向平台经营者的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。发生上述情形的，平台经营者可立即终止与您的所有合作，并依据平台规则进行处理，涉及严重违法行为的，将移送司法机关处理。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;">
            您保证在使用平台过程中所获悉的属于平台及他方的且无法自公开渠道获得的文件及资料（包括但不限于商业秘密、公司计划、运营活动、财务信息、技术信息、经营信息及其他商业秘密）予以保密。未经该资料和文件的原提供方同意，用户不得向第三方泄露该商业秘密的全部或者部分内容。但法律、法规、行政规章另有规定或者双方另有约定的除外。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;">
            <b><u>如您因严重违约导致平台经营者终止本协议时，出于维护平台秩序及保护消费者权益的目的，平台经营者可对与您在其他协议项下的合作采取中止甚或终止协议的措施，并以本协议内约定的方式通知您。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.2pt;">
            <b><u>如平台经营者与您签署的其他协议及平台经营者及其关联公司与您签署的协议中明确约定了对您在本协议项下合作进行关联处理的情形，则平台经营者出于维护平台秩序及保护消费者权益的目的，可在收到指令时中止甚至终止协议，并以本协议内约定的方式通知您。</u></b><span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>平台责任限制<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>平台经营者依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，平台经营者并不承担相应的违约责任：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>9.1.1&nbsp;&nbsp;&nbsp; </b>因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>9.1.2&nbsp;&nbsp;&nbsp; </b>因电力供应故障、通讯网络故障等公共服务因素或第三人因素；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>9.1.3&nbsp;&nbsp;&nbsp; </b>在平台经营者已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>平台经营者不能随时预见到任何技术上的问题或其他困难。该等困难可能会导致数据损失或其他服务中断。平台经营者是在现有技术基础上提供的服务。平台经营者不保证以下事项：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>9.2.1&nbsp;&nbsp;&nbsp; </b>平台将完全符合您的要求；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>9.2.2&nbsp;&nbsp;&nbsp; </b>平台不受干扰、能够及时提供、安全可靠或免于出错；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>9.2.3&nbsp;&nbsp;&nbsp; </b>平台服务使用权的取得结果是正确或可靠的。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>9.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>是否经由平台下载或取得任何资料，由您自行考虑、衡量并且自负风险，因下载任何资料而导致您电脑系统的任何损坏或资料流失，您应负完全责任。希望您在使用平台时，小心谨慎并运用常识。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>9.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>您经由平台取得的建议和资讯，无论其形式或表现，绝不构成本协议未明示规定的任何保证。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>9.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>基于以下原因而造成的利润、商誉、使用、资料损失或其它无形损失，平台经营者不承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿（即使平台经营者已被告知前款赔偿的可能性）：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>9.5.1&nbsp;&nbsp;&nbsp; </b>平台的限制使用或无法使用；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>9.5.2&nbsp;&nbsp;&nbsp; </b>您的传输或资料遭到未获授权的存取或变更；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>9.5.3&nbsp;&nbsp;&nbsp; </b>任何第三方之声明或行为；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>9.5.4&nbsp;&nbsp;&nbsp; </b>平台其它相关事宜。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>9.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>平台提供与其它互联网上的网站或资源的链接，您可能会因此连结至其它运营商经营的网站，但不表示平台经营者与这些运营商有任何关系。其它运营商经营的网站均由各经营者自行负责，不属于本平台经营者控制及负责范围之内。对于存在或来源于此类网站或资源的任何内容、广告、物品或其它资料，平台经营者亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、物品或服务所产生的任何损害或损失，平台经营者不负任何直接或间接的责任。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>9.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>平台经营者仅仅向您提供平台服务，您了解平台上的信息系用户自行发布，且可能存在风险和瑕疵。平台经营者将通过依法建立相关检查监控制度尽可能保障您在平台上的合法权益及良好体验。同时，鉴于平台在信息网络环境下信息与实际服务相分离的特点，平台经营者无法逐一审查信息质量、安全以及合法性、真实性、准确性，对此您应谨慎判断。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>9.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b><u>平台经营者通过中华人民共和国境内的设施控制和提供平台服务，平台经营者不担保控制或提供的服务在其他国家或地区是适当的、可行的，任何在其他司法辖区使用平台服务的用户应自行确保其遵守当地的法律法规，平台经营者对此不承担任何责任。<span></span></u></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>协议的变更<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            平台经营者可根据国家法律法规变化、平台商业模式的调整及维护秩序、保护其他用户权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过法定程序并以本协议内约定的方式通知您。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            如您不同意变更事项，您有权于变更事项确定的生效日前联系平台经营者反馈意见。如反馈意见得以采纳，平台经营者将酌情调整变更事项。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用平台服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用平台服务，则视为您同意已生效的变更事项。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>通知<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>11.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>有效联系方式<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            您在注册成为平台用户，并使用平台服务时，您应该向平台经营者提供真实有效的联系方式（包括但不限于您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            您在注册成为平台用户时生成的用于登陆平台接收系统消息和即时信息的会员帐号，也将视为您的有效联系方式。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            平台经营者将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            您有权通过您注册时填写的手机号码或者电子邮箱获取您感兴趣的商品广告信息、促销优惠等商业性信息；您如果不愿意接收此类信息，您有权联系平台客服人员处理。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>11.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>通知的送达</b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            平台经营者通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的帐号发送即时信息及系统消息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第七个自然日即视为送达。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            对于在平台上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件或即时信息等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱或平台帐号等联系方式为您在平台注册、更新时提供的手机号码、电子邮箱联系方式以及在注册成为平台用户时生成平台帐号，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            您应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>12&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>协议的终止<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>12.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>终止的情形<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>12.1.1</b>在满足平台公示的账户注销条件时您通过网站自助服务注销您的账户的；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>12.1.2</b>变更事项生效前您停止使用并明示不愿接受变更事项的；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>12.1.3</b>您明示不愿继续使用平台服务，且符合平台终止条件的。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>12.1.4</b>您违反本协议约定及《企修通平台退崔规范》，平台经营者依据违约条款终止本协议的；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>12.1.5</b>平台经营者发现您的注册资料中主要内容是虚假的，平台经营者有权随时终止本协议；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>12.1.6</b>您盗用他人账户、发布违禁信息、骗取他人财物、扰乱市场秩序、采取不正当手段谋利等行为，及<span>/</span>或其他违反法律法规及平台规则，平台经营者依据平台规则对您的账户予以查封的；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>12.1.7</b>您的账户被平台经营者依据本协议回收的；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>12.1.8</b>其它应当终止服务的情况。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:49.6pt;text-indent:-1.0cm;">
            <b>12.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>协议终止后的处理<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            本协议终止后，除法律有明确规定外，平台经营者无义务向您或您指定的第三方披露您账户中的任何信息。您再一次直接或间接或以他人名义注册为平台用户的，平台经营者有权拒绝或者在注册成功后单方面再次终止本协议。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            本协议终止后，平台经营者仍享有下列权利：<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>12.2.1</b>继续保存您留存于平台的各类合法合规信息；<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:70.9pt;text-indent:-1.0cm;">
            <b>12.2.2</b>对于您过往的违约行为，平台经营者仍可依据本协议向您追究违约责任。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            本协议终止后，对于您在本协议存续期间产生的交易订单，平台经营者可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;text-indent:-21.25pt;">
            <b>13&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b><b>法律适用、管辖及其他<span></span></b>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及<span>/</span>或行业惯例。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            您因使用平台服务所产生及与平台服务有关的争议，由平台经营者与您协商解决。协商不成时，您同意将纠纷或争议提交至平台经营者所在地有管辖权的司法机构管辖。
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            <br />
        </p>
        <p class="MsoNormal">
            <b><span>&nbsp;</span>本规定自颁布之日起执行。<span></span></b>
        </p>
        <p class="MsoListParagraph" style="text-indent:0cm;">
            <b><span>&nbsp;</span>本规定修订权及最终解释权归企修通所有。</b><span></span>
        </p>
        <p class="MsoListParagraph" align="right" style="margin-left:21.3pt;text-align:right;text-indent:0cm;">
            重庆企修通网络科技有限公司<span></span>
        </p>
        <p class="MsoListParagraph" style="margin-left:21.25pt;">
            <br />
        </p>
        <p>
            <br />
        </p>
        <p>
            <br />
        </p>
        <div style="line-height: 30px;cursor: pointer;color: blue"><a @click="fileDown">企修通服务协议附件下载</a></div>
    </div>
</template>

<script>
    export default {
        name: "ServiceAgreement",
        methods:{
            fileDown(){
                window.location.href = '/word/企修通平台服务协议.docx'
            }
        }
    }
</script>

<style scoped>

</style>
